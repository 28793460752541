import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import styled, { css } from "styled-components";
import "./NavbarMain.css";

// import { useNavigate } from "react-router-dom";
// import { useAuth, logOut } from "../../utils/Auth";

// import { getUserName, getPermision } from "../../utils/Auth";

const fontFamilyUbuntuBold = css`
  font-family: "UbuntuBold";
`;

const Text = styled.span`
  ${fontFamilyUbuntuBold};
  font-size: 1rem;
  color: ${({ color }) => color};
  &:hover {
    color: #641fe3;
    cursor: pointer;
  }
`;

let activeStyleUsers = {
  borderRight: "1px solid rgba(39, 39, 39, 0.25)",
  borderTop: "1px solid rgba(39, 39, 39, 0.25)",
  borderLeft: "1px solid rgba(39, 39, 39, 0.25)",
  borderRadius:
    "5px 5px 0px 0px" /* top left, top right, bottom right, bottom left */,
};

let inactiveStyleUsers = {
  borderRight: "none",
  borderBottom: "1px solid rgba(39, 39, 39, 0.25)",
  borderTop: "none",
};

let activeStyleRoles = {
  borderRight: "1px solid rgba(39, 39, 39, 0.25)",
  borderTop: "1px solid rgba(39, 39, 39, 0.25)",
  borderLeft: "1px solid rgba(39, 39, 39, 0.25)",
  borderRadius:
    "5px 5px 0px 0px" /* top left, top right, bottom right, bottom left */,
};

let inactiveStyleRoles = {
  borderRight: "none",
  borderBottom: "1px solid rgba(39, 39, 39, 0.25)",
  borderTop: "none",
  borderLeft: "none",
  borderRadius:
    "10px 10px 0px 0px" /* top left, top right, bottom right, bottom left */,
};

let activeLinkStyle = {
  textDecoration: "none",
  color: "#641fe3",
};

let inactiveLinkStyle = {
  textDecoration: "none",
  color: "rgba(28, 28, 28, 0.25)",
};

const NavbarSettings = () => {
  // let userName = getUserName("approval_user_name");
  // let isAdmin = getPermision("approval_admin_role");
  // let isAdmin = true;

  const [isActiveTab, setIsActiveTab] = useState("users");

  // useEffect(() => {
  //   localStorage.setItem("settings_tab", "users");
  //   setIsActiveTab("users");
  // }, []);

  useEffect(() => {
    if (localStorage.getItem("settings_tab") === "users") {
      setIsActiveTab("users");
    } else if (localStorage.getItem("settings_tab") === "roles") {
      setIsActiveTab("roles");
    }
  }, [isActiveTab]);
  return (
    <>
      <Navbar
        className="p-0 mb-3"
        style={{
          marginTop: "-1rem",
        }}
      >
        <Container fluid="md" className="">
          <Row className="w-100 justify-content-center">
            <Col sm="11" className="">
              <Row>
                <Col sm={1} className=""></Col>
                <Col
                  sm="auto"
                  className=""
                  style={
                    isActiveTab === "users"
                      ? activeStyleUsers
                      : inactiveStyleUsers
                  }
                >
                  <Row className="g-0 w-100 justify-content-center align-items-center">
                    <Col xs="auto" className="">
                      <Nav.Link
                        as={Col}
                        // xs="auto"
                        // className="ps-sm-5 pe-sm-3 pe-0 d-flex justify-content-center align-items-center"
                      >
                        <NavLink
                          to="users"
                          onClick={() => {
                            localStorage.setItem("settings_tab", "users");
                            setIsActiveTab("users");
                          }}
                          style={({ isActive }) =>
                            isActive ? activeLinkStyle : inactiveLinkStyle
                          }
                        >
                          <Text className="">USERS</Text>
                        </NavLink>
                      </Nav.Link>
                    </Col>
                  </Row>
                </Col>
                <Col
                  sm="auto"
                  className=""
                  style={
                    isActiveTab === "roles"
                      ? activeStyleRoles
                      : inactiveStyleRoles
                  }
                >
                  <Nav.Link as={Col} xs="auto" className="">
                    <NavLink
                      to="roles"
                      onClick={() => {
                        localStorage.setItem("settings_tab", "roles");
                        setIsActiveTab("roles");
                      }}
                      style={({ isActive }) =>
                        isActive ? activeLinkStyle : inactiveLinkStyle
                      }
                    >
                      <Text>ROLES AND PERMISSIONS</Text>
                    </NavLink>
                  </Nav.Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarSettings;
