import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { FaTrash } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const ColContent = styled.span`
  ${fontFamilyUbuntu}
  font-weight: ${(props) => (props.isHoovered ? "700" : "400")};
  font-size: 1rem;
  line-height: 21px;
  color: ${(props) => (props.isHoovered ? "#641FE3" : "#5E5C5C")};
  inline-size: 150px;
  overflow-wrap: break-word;
`;

const RowWrapper = styled.div`
  text-align: center;
  border-bottom: ${(props) => (props.isHoovered ? "2px" : "1px")} solid;
  border-bottom-color: ${(props) => (props.isHoovered ? "#641FE3" : "#DEDDDD")};
  padding-bottom: 0.5rem;
  padding-top: 1.5rem;
  width: 90%;
`;

const RoleRow = ({ id, name, description, openEditModal, opedDeleteModal }) => {
  const [hoovered, setHoovered] = useState(false);

  return (
    <RowWrapper isHoovered={hoovered}>
      <Row
        className="g-0"
        onMouseEnter={() => setHoovered(true)}
        onMouseLeave={() => setHoovered(false)}
      >
        <Col xs={3} className="text-start ps-3">
          <ColContent isHoovered={hoovered}>{name}</ColContent>
        </Col>
        <Col xs={6} className="text-start">
          <ColContent isHoovered={hoovered}>{description}</ColContent>
        </Col>
        <Col xs={3} className="text-end pe-3">
          <BsPencilFill
            className="me-3"
            color={hoovered ? "#641FE3" : "#DEDDDD"}
            cursor="pointer"
            onClick={() => openEditModal(id)}
          />
          <FaTrash
            color={hoovered ? "#641FE3" : "#DEDDDD"}
            cursor="pointer"
            onClick={() => opedDeleteModal(id)}
          />
        </Col>
      </Row>
    </RowWrapper>
  );
};

export default RoleRow;
// isHoovered = { hoovered };
