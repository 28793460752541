import React from "react";
import styled, { css } from "styled-components";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const Button = styled.button`
  ${fontFamilyUbuntu};
  border: 0.125rem solid #ebebeb;
  background: white;
  border-radius: 27px;
  padding: 0;
  font-weight: bold;
  font-size: 0.875rem;
  color: #641fe3;
  &:hover {
    border-color: #641fe3;
  }
  label {
    border-radius: 27px;
    cursor: pointer;
    padding: 0.25rem 1.5rem;
  }
  input[type="file"] {
    display: none;
  }
`;

const UploadBtn = ({ title, handleFileChange }) => {
  return (
    <Button className="g-0">
      <label htmlFor="file-upload">
        {title}
        <input
          type="file"
          id="file-upload"
          accept="application/pdf"
          onChange={handleFileChange}
        />
      </label>
    </Button>
  );
};

export default UploadBtn;
