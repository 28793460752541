import React from "react";
import styled from "styled-components";
import CurrencySelect from "../Dropdowns/CurrencySelect";

const InputContainer = styled.div`
  border: 2px solid #ebebeb;
  border-radius: 25px;
  width: 100%;
  height: 2rem;
  color: #5e5c5c;
  display: flex;
  flex-direction: row;
`;

const CustomInput = styled.input`
  border-style: none;
  border-left-style: solid;
  border-left-color: #ebebeb;
  max-width: 100%;
  width: 70%;
  text-align: center;
  color: #5e5c5c;
  &:focus-visible {
    outline-style: none;
  }
`;

const Input = ({
  onChange,
  placeholder,
  value,
  isSecure,
  id,
  onClick,
  selectedAmountType,
  handleAmountTypeChange,
  amountRef,
}) => {
  return (
    <InputContainer>
      <CurrencySelect
        selectedValue={selectedAmountType}
        handleAmountTypeChange={handleAmountTypeChange}
      />
      <CustomInput
        /*type='number'*/
        ref={amountRef}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        isSecure={isSecure}
        id={id}
        onClick={onClick}
        /*step="1"*/
      />
    </InputContainer>
  );
};

export default Input;
