import { createGlobalStyle } from "styled-components";
import UbuntuWoff from "./fonts/Ubuntu-v19-regular.woff";
import UbuntuWoff2 from "./fonts/Ubuntu-v19-latin-ext.woff2";
import RobotoWoff from "./fonts/Roboto-v29-regular.woff";
import RobotoWoff2 from "./fonts/Roboto-v29-latin-ext.woff2";
import RobotoBold from "./fonts/roboto-v29-latin-900.woff";
import RobotoBold2 from "./fonts/roboto-v29-latin-900.woff2";
import UbuntuBold from "./fonts/ubuntu-v19-latin-700.woff";
import UbuntuBold2 from "./fonts/ubuntu-v19-latin-700.woff2";

const FontStyles = createGlobalStyle`

@font-face {
  font-family: 'Ubuntu';
  src: url(${UbuntuWoff}) format('woff2'),
       url(${UbuntuWoff2}) format('woff');
}

@font-face {
  font-family: 'UbuntuBold';
  font-weight: 900;
  src: url(${UbuntuBold}) format('woff2'),
       url(${UbuntuBold2}) format('woff');
}

@font-face {
  font-family: 'Roboto';
  src: url(${RobotoWoff}) format('woff2'),
       url(${RobotoWoff2}) format('woff');
}
    
@font-face {
  font-family: 'RobotoBold';
  font-weight: 900;
  src: url(${RobotoBold}) format('woff2'),
       url(${RobotoBold2}) format('woff');
}

body {
  font-family: Ubuntu, Roboto, sans-serif;
}
`;

export default FontStyles;
