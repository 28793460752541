import { useState, useRef } from "react";
import styled, { css } from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InvoiceInput from "../Input/RoundedInput";
import AmountInput from "../Input/AmountInput";
import DatePick from "../Date/DatePicker";
import ConfirmButton from "../Buttons/ConfirmButton";
import SupplierHyperlink from "../HyperLinks/SupplierHyperlink";
import NewSupplierModal from "../Modal/NewSupplierModal";
import Autocomplete from "../Autocomplete";
import ApiService from "../../api/services";
import loading from "../../assets/loading.gif";
import CustomSelect from "../Select/CustomSelect";
import moment from "moment";
import {
  msgNewSupplierEmptyFields,
  msgSupplierExist,
} from "../../utils/Messages";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const SettingsTitle = styled.span`
  ${fontFamilyUbuntu}
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 20.68px;
  margin-left: 2rem;
  color: #000000;
  text-align: start;
`;

const OptionsLabel = styled.span`
  ${fontFamilyUbuntu}
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 16.09px;
  color: #000000;
  margin-left: 1rem;
  margin-bottom: 0.25rem;
`;

const SettingsContainer = styled.div`
  margin-top: 2rem;
  padding: 0.5rem;
`;

const OptionsContainer = styled.div`
  padding: 3rem 2rem;
`;

const SelectorContainer = styled.div`
  margin-top: 0.5rem;
  width: 100%;
`;

const LabelStartPosition = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

const SubmitApproveRow = styled(Row)`
  margin-top: ${({ isupperrow }) => (isupperrow ? "5rem" : "2rem")};
  width: 75%;
`;

const SubmittedMessage = styled.div`
  ${fontFamilyUbuntu}
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 16px;
  color: #8d8d8d;
  margin-top: 1.5rem;
`;

const AutoCompleteContainer = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  position: relative;
`;

const ErrorMessage = styled.div`
  color: #641fe3;
`;

const CreateInvoiceMessage = styled.div`
  color: #641fe3;
`;

const ApprovalSettings = ({
  companies,
  id,
  status,
  pdfReport,
  handleSubmitInvoice,
  submitApprovalMessage,
  amount,
  amountType,
  supplierName,
  company,
  invoiceNumber,
  date,
  handleChange,
  setIsOpen,
  suppliers,
  setSuppliers,
  getSupplierId,
  token,
  reload,
  setReload,
  notes,
  // ******Messages
  newSupplierMessage,
  setNewSupplierMessage,
  createInvoiceMessage,
  setCreateInvoiceMessage,
  invoiceNumberMessage,
  setInvoiceNumberMessage,
  supplierNameMessage,
  setSupplierNameMessage,
  amountMessage,
  setAmountMessage,
}) => {
  const amountRef = useRef(null);
  /***invoice functions***/
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  /***suppliers functions***/
  const [selectedSupplierType, setSelectedSupplierType] = useState("Regular");
  const [newSupplier, setNewSupplier] = useState("");
  const [newSuppliers, setNewSuppliers] = useState(suppliers);
  
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  /***suppliers functions***/

  /***default values***/
  const supplierTypes = ["Regular", "Exception"];

  const handleChangeAmount = (e) => {
    handleChange(e.target.value.replace(/[^\d.]/g, ""), "amount");
  };

  // const retrieveSuppliers = async (search) => {
  //   try {
  //     let res = await ApiService.getAllSuppliersSearch(token, search);
  //     if (res && !res.isAxiosError) {
  //       const { data } = { ...res };
  //       if (data?.data) {
  //         setNewSuppliers(data.data);
  //       }
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const handleChangeInvoiceNumber = (e) => {
    handleChange(e.target.value, "invoiceNumber");
  };

  const handleNewSupplierModal = () => {
    setShowModal(true);
  };

  const handleNewSupplier = (e) => {
    setNewSupplier(e.target.value);
  };

  const closeNewSupplierModal = () => {
    setNewSupplierMessage("");
    setNewSupplier("");
    setShowModal(false);
  };

  const handleNewSupplierSubmit = async () => {
    if (newSupplier && selectedSupplierType) {
      setSubmitting(true);
      try {
        let res = await ApiService.createSupplier(
          newSupplier,
          selectedSupplierType,
          token
        );
        if (res && !res.isAxiosError) {
          let newSupplierId = res.data?.data?.id;
          setNewSupplierMessage("Created!");
          setShowModal(false);
          if (newSupplierId) {
            setNewSuppliers([
              ...newSuppliers,
              {
                id: newSupplierId,
                name: newSupplier,
                type: selectedSupplierType,
              },
            ]);
          }
        } else if (res && res.isAxiosError) {
          setNewSupplierMessage(
            res.response.data.error === "The given data was invalid." &&
              msgSupplierExist
          );
          setTimeout(() => {
            setNewSupplierMessage("");
          }, 2000);
        }
      } catch (err) {
        console.error(err);
      }
      setSubmitting(false);
    } else {
      setNewSupplierMessage(msgNewSupplierEmptyFields);
      setTimeout(() => {
        setNewSupplierMessage("");
      }, 2000);
    }
  };

  const handleSupplierName = (val) => {
    handleChange(val, "supplierName");
  };

  const handleCompanyName = (val) => {
    handleChange(val, "company");
  };

  const handleAmountTypeChange = (e) => {
    handleChange(e.target.value, "amountType");
  };

  const handleDateChange = (val) => {
    handleChange(moment(val, "DD/MM/YYYY").format("DD/MM/YYYY"), "date");
  };

  const clearValidationMessages = () => {
    setInvoiceNumberMessage("");
    setSupplierNameMessage("");
    setAmountMessage("");
  };

  const handleCreateInvoice = async () => {
    clearValidationMessages();
    setSubmitting(true);

    if (!invoiceNumber) {
      setInvoiceNumberMessage("Invoice field cannot be empty");
      setSubmitting(false);
      return;
    }

    if (!supplierName) {
      setSupplierNameMessage("Supplier field cannot be empty");
      setSubmitting(false);
      return;
    }

    if (!amount) {
      setAmountMessage("Amount field cannot be empty");
      setSubmitting(false);
      return;
    }

    let foundSupplierId = await getSupplierId(supplierName);

    if (!foundSupplierId) {
      setAmountMessage("Supplier not found, create supplier first.");
      setSubmitting(false);
      return;
    }

    clearValidationMessages();

    let res;

    if (!id) {
      //no id for newly uploaded invoice
      res = await ApiService.createInvoice(
        invoiceNumber,
        company,
        foundSupplierId,
        amount,
        amountType === "$" ? "USD" : "EUR",
        date,
        status,
        pdfReport,
        notes,
        token
      );
    } else {
      res = await ApiService.updateInvoice(
        id,
        "pending",
        invoiceNumber,
        company,
        amount,
        amountType === "$" ? "USD" : "EUR",
        foundSupplierId,
        date,
        notes,
        token
      );
    }

    if (res && !res.isAxiosError) {
      // let newId = res.data?.data?.id;
      // handleChange(newId, "id");

      // setCreateInvoiceMessage("Created!");

      setReload(!reload);
      //setIsOpen(false)
    } else if (res && res.isAxiosError) {
      setCreateInvoiceMessage(res.message);
    }
    setSubmitting(false);
  };

  const handleNotesChange = (e) => {
    let notes = e.target.value;
    /*if (notes?.split("\n").length > 2) {
      return;
    } else {
      handleChange(notes, "notes")
    }*/
    handleChange(notes, "notes");
  };

  return (
    <SettingsContainer>
      <Row>
        <SettingsTitle>Approval Settings</SettingsTitle>
      </Row>
      <OptionsContainer>
        <Row className="d-flex flex-row justify-content-between mt-2 mb-5">
          <Col className="d-flex flex-column align-items-start">
            <LabelStartPosition>
              <OptionsLabel>Invoice Number</OptionsLabel>
            </LabelStartPosition>
            <SelectorContainer>
              <InvoiceInput
                value={invoiceNumber}
                onChange={handleChangeInvoiceNumber}
              />
            </SelectorContainer>
          </Col>
          <Col className="d-flex flex-column align-items-start">
            <OptionsLabel>Company</OptionsLabel>
            <SelectorContainer>
              <CustomSelect
                isSupplierPage
                items={companies}
                setSelectedItem={handleCompanyName}
              />
            </SelectorContainer>
          </Col>
        </Row>

        <Row className="d-flex flex-row justify-content-between mt-2 mb-5">
          {/* Supplier */}
          <Col className="d-flex flex-column align-items-start">
            <LabelStartPosition>
              <OptionsLabel>Supplier</OptionsLabel>
            </LabelStartPosition>

            <AutoCompleteContainer>
              <Autocomplete
                suggestions={newSuppliers?.map((supplier) => supplier.name)}
                supplierName={supplierName}
                setSupplierName={handleSupplierName}
              />
            </AutoCompleteContainer>
          </Col>
          {/* New Supplier */}
          <Col className="d-flex flex-column align-items-center justify-content-end">
            <SupplierHyperlink
              title="Create New Supplier"
              onClick={handleNewSupplierModal}
            />
          </Col>
        </Row>

        <Row className="d-flex flex-row justify-content-between align-items-start mt-2 mb-5">
          {/* Date */}
          <Col xs={6} className="d-flex flex-column align-items-start">
            <LabelStartPosition>
              <OptionsLabel>Date</OptionsLabel>
            </LabelStartPosition>
            <SelectorContainer>
              <DatePick
                // isDateFocused={isDateFocused}
                // setIsDateFocused={setIsDateFocused}
                amountRef={amountRef}
                selectedDate={date}
                setSelectedDate={handleDateChange}
              />
            </SelectorContainer>
          </Col>
          {/* Amount */}
          <Col xs={6} className="d-flex flex-column align-items-start">
            <LabelStartPosition>
              <OptionsLabel>Amount</OptionsLabel>
            </LabelStartPosition>
            <SelectorContainer>
              <AmountInput
                // isDateFocused={isDateFocused}
                // setIsDateFocused={setIsDateFocused}
                amountRef={amountRef}
                value={amount}
                onChange={handleChangeAmount}
                selectedAmountType={amountType}
                handleAmountTypeChange={handleAmountTypeChange}
              />
            </SelectorContainer>
          </Col>
        </Row>
        <Row className="d-flex flex-row justify-content-between align-items-start mt-2 mb-5">
          {/* Notes */}
          <Col className="d-flex flex-column align-items-start">
            <LabelStartPosition>
              <OptionsLabel>Notes (optional)</OptionsLabel>
            </LabelStartPosition>
            <SelectorContainer>
              <InvoiceInput
                isTextArea={true}
                placeholder="Notes (max. 3 lines)"
                onChange={handleNotesChange}
                value={notes}
              />
            </SelectorContainer>
          </Col>
        </Row>

        {(invoiceNumberMessage || supplierNameMessage || amountMessage) && (
          <ErrorMessage>
            {invoiceNumberMessage || supplierNameMessage || amountMessage}
          </ErrorMessage>
        )}

        {createInvoiceMessage && (
          <CreateInvoiceMessage>{createInvoiceMessage}</CreateInvoiceMessage>
        )}

        <SubmitApproveRow
          className="mx-auto d-flex flex-row justify-content-evenly align-items-center"
          $isupperrow="true"
        >
          {submitting ? (
            <img
              src={loading}
              className="img-fluid"
              style={{ maxWidth: "20px" }}
              alt="..."
            />
          ) : (
            <ConfirmButton
              title="Save"
              styling="w-50 py-1"
              onClick={handleCreateInvoice}
            />
          )}
        </SubmitApproveRow>

        {status === "pending" && (
          <SubmitApproveRow
            className="mx-auto d-flex flex-row justify-content-evenly align-items-center"
            $isupperrow="false"
          >
            <ConfirmButton
              title="Submit for approval"
              onClick={() => handleSubmitInvoice()}
              styling="w-50 py-1"
            />
            {submitApprovalMessage && (
              <SubmittedMessage>{submitApprovalMessage}</SubmittedMessage>
            )}
          </SubmitApproveRow>
        )}

        <NewSupplierModal
          show={showModal}
          companies={companies}
          selectedCompanies={selectedCompanies}
          setSelectedCompanies={setSelectedCompanies}
          handleClose={closeNewSupplierModal}
          handleSubmit={handleNewSupplierSubmit}
          supplierName={newSupplier}
          setSupplierName={handleNewSupplier}
          supplierTypes={supplierTypes}
          selectedSupplierType={selectedSupplierType}
          setSelectedSupplierType={setSelectedSupplierType}
          message={newSupplierMessage}
          submitting={submitting}
        />
      </OptionsContainer>
    </SettingsContainer>
  );
};

export default ApprovalSettings;
