import React from "react";
import styled, { css } from "styled-components";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const Button = styled.button`
  ${fontFamilyUbuntu};
  border: 0.125rem solid #ebebeb;
  border-color: ${({ borderColor }) => borderColor || "#ebebeb"};
  background: white;
  border-radius: 27px;
  padding: 0.5rem 1.5rem;
  font-weight: bold;
  font-size: 0.875rem;
  color: ${({ color }) => color || "#641fe3"};
  &:hover {
    border-color: ${({ color }) => color || "#641fe3"};
  }
`;

const ConfirmButton = ({ title, onClick, styling, color, borderColor }) => {
  return (
    <Button
      className={`${styling}`}
      onClick={onClick}
      title={title}
      color={color}
      borderColor={borderColor}
    >
      {title}
    </Button>
  );
};

export default ConfirmButton;
