import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const CALLBACK_URL = process.env.REACT_APP_CALLBACK_URL;

class ApiService {
  // Suppliers

  async createSupplier(token, name, type, companies_ids) {
    try {
      const res = await axios.post(
        API_URL + "/create-supplier",
        {
          name: name,
          type: type,
          companies: JSON.stringify(companies_ids),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async getAllSuppliers(token, page, search) {
    try {
      const res = await axios.get(
        API_URL + `/all-suppliers?search=${search}&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async getAllSuppliersSearch(token, search) {
    try {
      const res = await axios.get(
        API_URL + `/all-suppliers?search=${search}&per_page=10000`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async deleteSupplier(id, token) {
    try {
      const res = await axios.delete(API_URL + `/delete-supplier/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async updateSupplier(token, id, name, type, companies_ids) {
    try {
      const res = await axios.post(
        API_URL + `/update-supplier/${id}`,
        {
          name: name,
          type: type,
          companies: JSON.stringify(companies_ids),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  // Invoices

  async getAllInvoices(token, status, page, search) {
    try {
      const res = await axios.get(
        API_URL +
          `/get-invoices?status=${status}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async createInvoice(
    invoiceNumber,
    company,
    supplierId,
    amount,
    amountType,
    date,
    status,
    pdfReport,
    notes,
    token
  ) {
    try {
      const res = await axios.post(
        API_URL + "/create-invoice",
        {
          invoice_number: invoiceNumber,
          company_id: company.id,
          supplier_id: supplierId,
          amount: amount,
          amount_type: amountType,
          date: date,
          status: status,
          pdf_report: pdfReport,
          notes: notes,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async deleteInvoice(id, token) {
    try {
      const res = await axios.delete(API_URL + `/delete-invoice/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async updateInvoice(
    id,
    status,
    invoiceNumber,
    company,
    amount,
    amountType,
    supplierId,
    date,
    notes,
    token
  ) {
    try {
      const res = await axios.post(
        API_URL + `/update-invoice/${id}`,
        {
          status: status,
          invoice_number: invoiceNumber,
          company: company,
          amount: amount,
          amount_type: amountType,
          supplier_id: supplierId,
          date: date,
          notes: notes,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async approveInvoice(id, token) {
    try {
      const res = await axios.post(
        API_URL + `/update-invoice/${id}`,
        {
          status: "approved",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async refreshInvoices(token) {
    try {
      const res = await axios.get(API_URL + `/refresh-invoices`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async declineInvoice(id, reason, token) {
    try {
      const res = await axios.post(
        API_URL + `/update-invoice/${id}`,
        {
          reason: reason,
          status: "declined",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  // Roles

  async getRoles(token) {
    try {
      const res = await axios.get(API_URL + "/get-roles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async createRole(token, name, description, permissions) {
    try {
      const res = await axios.post(
        API_URL + "/create-role",
        {
          name: name,
          description: description,
          permissions: JSON.stringify(permissions),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async deleteRole(token, id) {
    try {
      const res = await axios.delete(API_URL + `/delete-role/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async editRole(token, id, name, description, permissions) {
    try {
      const res = await axios.post(
        API_URL + `/edit-role/${id}`,
        {
          name: name,
          description: description,
          permissions: JSON.stringify(permissions),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  // Permissions

  async getPermissions(token) {
    try {
      const res = await axios.get(API_URL + "/get-permissions", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  // Users / Invites

  async getInvites(token) {
    try {
      const res = await axios.get(API_URL + "/get-invites", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async createInvite(token, email, role, companies) {
    try {
      const res = await axios.post(
        API_URL + "/create-invite",
        {
          email: email,
          role_id: role,
          companies: JSON.stringify(companies),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async editInvite(token, id, role_id, companies_ids) {
    try {
      const res = await axios.post(
        API_URL + `/update-invite/${id}`,
        {
          role_id: role_id,
          companies: JSON.stringify(companies_ids),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async deleteInvite(token, id) {
    try {
      const res = await axios.delete(API_URL + `/delete-invite/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  // Companies

  async getCompanies(token) {
    try {
      const res = await axios.get(API_URL + "/get-companies", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  // Login
  async sendCode(code, state) {
    try {
      const res = await axios.post(CALLBACK_URL, {
        code: code,
        state: state,
      });
      let obj = {
        tokenAccess: res?.data?.tokenAccess,
        userId: res?.data?.user.id,
        userName: res?.data?.user.name,
        isAdmin: res?.data?.isAdmin,
      };
      return obj;
    } catch (err) {
      console.error(err);
      return err.message;
    }
  }
}

export default new ApiService();
