import styled from "styled-components";

const Select = styled.select`
  border: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  cursor: pointer;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0 1rem;
  color: #8d8d8d;
  &:focus {
    outline: none;
  }

  option {
    color: #8d8d8d;
    font-weight: bold;
    cursor: pointer;
  }
`;

const CurrencySelect = ({ selectedValue, handleAmountTypeChange }) => {
  return (
    <Select value={selectedValue} onChange={handleAmountTypeChange}>
      <option>&euro;</option>
      <option>$</option>
    </Select>
  );
};

export default CurrencySelect;
