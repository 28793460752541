import React, { useState } from "react";
import WelcomeDynamic from "../components/Responsive/WelcomeDynamic";

import { useNavigate, useLocation } from "react-router-dom";
import {
  useAuth,
  setAccessToken,
  setIsAdmin,
  setUserId,
  setUserName,
  setPermision,
} from "../utils/Auth";
import ApiService from "../api/services";

const windowGlobal = typeof window !== "undefined" && window;

const SignUp = () => {
  const [showModal, setShowModal] = useState(false);

  let history = useNavigate();

  const handleClose = () => {
    history("/");
    setShowModal(false);
  };
  // const handleShow = () => setShowModal(true);

  let message =
    "You are not allowed to log in. Please contact your administrator.";
  // let location = useLocation();
  // let auth = useAuth();

  const sendCode = async (code, state) => {
    let res = await ApiService.sendCode(code, state);

    // console.log(res.message);
    try {
      if (res && !res.tokenAccess.includes("Error")) {
        setAccessToken(res.tokenAccess);
        setUserId(res.userId);
        setIsAdmin(res.isAdmin);
        setUserName(res.userName);
        history("/home");
      }
    } catch (error) {
      console.log(error);
      setShowModal(true);

    }
  };

  // let login = () => {
  //   sendCode();

  //   auth.signin(() => {
  //     history("/home");
  //   });
  // };

  if (windowGlobal) {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const state = queryParams.get("session_state");
    if (code) sendCode(code, state);
  }

  return (
    <WelcomeDynamic
      // login={login}
      showModal={showModal}
      handleClose={handleClose}
      message={message}
    />
  );
};

export default SignUp;
