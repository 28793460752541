function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date, join) {
  //formats date as dd/mm/yyyy
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join(join);
}

function getTodayDateFormatted(){
  let date = new Date();
  let day = String(date.getDate()).padStart(2, "0");
  let month = String(date.getMonth() + 1).padStart(2, "0");
  let year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export {formatDate, getTodayDateFormatted};