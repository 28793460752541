import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { FaTrash } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import { extractNameFromEmail } from "../../utils/UI";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const ColContent = styled.span`
  ${fontFamilyUbuntu}
  font-weight: ${(props) => (props.isHoovered ? "700" : "400")};
  font-size: 1rem;
  line-height: 21px;
  color: ${(props) => (props.isHoovered ? "#641FE3" : "#5E5C5C")};
  inline-size: 150px;
  overflow-wrap: break-word;
`;

const RowWrapper = styled.div`
  text-align: center;
  border-bottom: ${(props) => (props.isHoovered ? "2px" : "1px")} solid;
  border-bottom-color: ${(props) => (props.isHoovered ? "#641FE3" : "#DEDDDD")};
  padding-bottom: 0.5rem;
  padding-top: 1.5rem;
  /* background: blue; */
  width: 90%;
`;

const UserRow = ({ id, email, role, openEditModal, opedDeleteModal }) => {
  const [hoovered, setHoovered] = useState(false);

  return (
    <RowWrapper isHoovered={hoovered}>
      <Row
        className="g-0"
        onMouseEnter={() => setHoovered(true)}
        onMouseLeave={() => setHoovered(false)}
      >
        {/* <Col xs={10}>
          <Row className="g-0"> */}
        <Col xs={3} className="text-start ps-3">
          <ColContent isHoovered={hoovered}>
            {extractNameFromEmail(email)}
          </ColContent>
        </Col>
        <Col xs={4} className="text-start">
          <ColContent isHoovered={hoovered}>{email}</ColContent>
        </Col>
        <Col xs={3} className="text-start">
          <ColContent isHoovered={hoovered}>{role?.name}</ColContent>
        </Col>
        <Col xs={2} className="text-end pe-3">
          <BsPencilFill
            className="me-3"
            color={hoovered ? "#641FE3" : "#DEDDDD"}
            cursor="pointer"
            onClick={() => openEditModal(id)}
          />
          <FaTrash
            color={hoovered ? "#641FE3" : "#DEDDDD"}
            cursor="pointer"
            onClick={() => opedDeleteModal(id)}
          />
        </Col>
        {/* </Row>
        </Col> */}
      </Row>
    </RowWrapper>
  );
};

export default UserRow;
// isHoovered = { hoovered };
