import React from "react";
import NavbarMain from "../Navbar/NavbarMain";

const LayoutWrapper = ({ children, page }) => {
  return (
    <>
      <NavbarMain page={page} />
      {children}
    </>
  );
};

export default LayoutWrapper;
