import React, { useState, useCallback } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaPlus, FaMinus, FaTrash } from "react-icons/fa";
import styled, { css } from "styled-components";
import PdfViewer from "../PDF/PdfViewer";
import ApprovalSettings from "../InvoiceSettings";
import { RiArrowDropDownLine } from "react-icons/ri";
import ConfirmButton from "../Buttons/ConfirmButton";
import DeclineModal from "../Modal/DeclineModal";
import EmailModal from "../Modal/EmailModal";
import { longWordDisplay } from "../../utils/Text";
import ApiService from "../../api/services";
import { getUserName } from "../../utils/Auth";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const ColContent = styled.span`
  ${fontFamilyUbuntu}
  font-weight: ${(props) => (props.isHoovered ? "700" : "400")};
  font-size: 1rem;
  line-height: 21px;
  color: ${(props) => (props.isHoovered ? "#641FE3" : "#5E5C5C")};
  inline-size: 150px;
  overflow-wrap: break-word;
`;

const RowWrapper = styled.div`
  text-align: center;
  border-bottom: ${(props) => (props.isHoovered ? "2px" : "1px")} solid;
  border-bottom-color: ${(props) => (props.isHoovered ? "#641FE3" : "#DEDDDD")};
  padding-bottom: 0.5rem;
  padding-top: 1.5rem;
`;

const PdfViewerContainer = styled.div`
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width};
`;

const ApprovalText = styled.span`
  color: #5e5c5c;
  font-weight: bold;
  font-size: 0.9rem;
  cursor: pointer;
`;

const TitleApprove = styled.span`
  font-family: Ubuntu;
  font-weight: 700;
  font-size: 1.1rem;
  color: #000000;
`;

const Notes = styled.span`
  font-family: Ubuntu;
  font-weight: 400;
  font-style: italic;
  font-size: 1rem;
  color: #000000;
`;

const Note = styled.span`
  font-family: Ubuntu;
  font-weight: 700;
  font-size: 1rem;
  color: #000000;
`;

const InvoiceRow = (props) => {
  let {
    id,
    user,
    updated_at,
    userAccessId,
    invoice_number,
    approved_users,
    companies,
    company,
    amount,
    amount_type,
    supplier,
    date,
    status,
    isUploaded,
    pdf_report,
    notes,
    invoices,
    setInvoices,
    expanded,
    suppliers,
    setSuppliers,
    token,
    reload,
    setReload,
    openModal,
    retrieveInvoices,
  } = props;

  const userName = getUserName();
  console.log(invoices, "xxxxxxxxxx")

  const [invoice, setInvoice] = useState({
    id: id,
    invoiceNumber: invoice_number,
    company: company,
    amount: amount,
    amountType: amount_type === "USD" ? "$" : "&euro;",
    supplierName: supplier?.name,
    date: date,
    status: status,
    pdfReport: pdf_report?.replace(
      "dataapplication/pdfbase64",
      "data:application/pdf;base64,"
    ),
    notes: notes,
  });
  const [hoovered, setHoovered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  // const [isApproveModal, setIsApproveModal] = useState(false);
  const [isOpen, setIsOpen] = useState(!!expanded);
  const [declineMessage, setDeclineMessage] = useState("");
  /***messages***/
  const [newSupplierMessage, setNewSupplierMessage] = useState("");
  const [createInvoiceMessage, setCreateInvoiceMessage] = useState("");
  const [invoiceNumberMessage, setInvoiceNumberMessage] = useState("");
  const [supplierNameMessage, setSupplierNameMessage] = useState("");
  const [amountMessage, setAmountMessage] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  //
  const [submitApprovalMessage, setSubmitApprovalMessage] = useState("");
  const clearValidationMessages = () => {
    setInvoiceNumberMessage("");
    setSupplierNameMessage("");
    setAmountMessage("");
  };

  const handleClose = () => setShowModal(false);

  const handleAcceptInvoice = async (id) => {
    try {
      let res = await ApiService.approveInvoice(id, token);
      if (res && !res.isAxiosError) {
        // setIsApproveModal(true);
        if (res.data.data.status === "partially_approved") {
          setEmailMessage(
            "You approved the invoice which now is partially approved. To complete the approval process, the invoice must be approved by another admin!"
          );
        } else if (res.data.data.status === "fully_approved") {
          setEmailMessage("You approved the invoice");
        }
        setShowEmailModal(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeclineInvoice = async (id, reason) => {
    try {
      await ApiService.declineInvoice(id, reason, token);
      setShowModal(false);
      setEmailMessage("You declined the invoice!");
      setShowEmailModal(true);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (val, attrName) => {
    let updatedValue = {};
    if (attrName === "company") {
      updatedValue[attrName] = {id: val.id, name: val.name};
    } else updatedValue[attrName] = val;

    setInvoice((invoice) => ({
      ...invoice,
      ...updatedValue,
    }));
  };

  const handleSubmitInvoice = async () => {
    clearValidationMessages();

    if (!invoice.invoiceNumber) {
      setInvoiceNumberMessage("Invoice field cannot be empty");
      return;
    }
    if (!invoice.supplierName) {
      setSupplierNameMessage("Supplier field cannot be empty");
      return;
    }
    if (!invoice.amount) {
      setAmountMessage("Amount field cannot be empty");
      return;
    }

    let foundSupplierId = await getSupplierId(invoice.supplierName);
    // first - for already saved invoice
    if (foundSupplierId && invoice.id) {
      try {
        let res = await ApiService.updateInvoice(
          invoice?.id,
          "submitted",
          invoice?.invoiceNumber,
          invoice?.company.id,
          invoice?.amount,
          invoice?.amountType === "$" ? "USD" : "EUR",
          foundSupplierId,
          invoice?.date,
          invoice?.notes,
          token
        );
        if (res) {
          setIsOpen(false);
          let result = invoices.filter((invoice) => invoice.id !== id);
          setInvoices(result);
          retrieveInvoices();
        }
      } catch (err) {
        console.error(err);
        setSubmitApprovalMessage(err);
      }
    }
    // second - for new invoice with no supplier found
    else if (!foundSupplierId && !invoice.id) {
      setSupplierNameMessage("Supplier not found, create supplier first");
      return;
    }
    // third - for new invoice with supplier found
    else if (foundSupplierId && !invoice.id) {
      let res = await ApiService.createInvoice(
        invoice.invoiceNumber,
        invoice.company.id,
        foundSupplierId,
        invoice.amount,
        invoice.amountType === "$" ? "USD" : "EUR",
        invoice.date,
        "submitted",
        invoice.pdfReport,
        invoice.notes,
        token
      );
      if (res && !res.isAxiosError) {
        // let newId = res.data?.data?.id;
        // handleChange(newId, "id");

        // setCreateInvoiceMessage("Created!");

        setReload(!reload);
        //setIsOpen(false)
      }
    } else if (!foundSupplierId && invoice.id) {
      setSupplierNameMessage("Supplier not found, create supplier first");
      return;
    }

    //setSubmitting(false);
  };

  ////////////////////////////////////////////

  const getSupplierId = async (name) => {
    try {
      let res = await ApiService.getAllSuppliersSearch(token, name);
      if (res) {
        const { data } = { ...res };
        let supppliersData = data?.data;
        let found = supppliersData.find((supp) => supp.name === name);
        return found?.id;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const timeZoneFormat = (str) => {
    let timeZone = new Date(str);
    let min = timeZone.getMinutes().toString().padStart(2, "0");
    let hour = timeZone.getHours().toString().padStart(2, "0");
    let day = timeZone.getDate().toString().padStart(2, "0");
    let month = (timeZone.getMonth() + 1).toString().padStart(2, "0");
    let year = timeZone.getFullYear().toString().padStart(2, "0");
    // let gmt = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let gmt = timeZone
      .toLocaleDateString("default", {
        day: "2-digit",
        timeZoneName: "short",
      })
      .slice(4);

    return `${day}/${month}/${year}, ${hour}:${min} (${gmt})`;
  };

  return (
    <RowWrapper isHoovered={hoovered}>
      {isUploaded && (
        <Row
          onMouseEnter={() => setHoovered(true)}
          onMouseLeave={() => setHoovered(false)}
        >
          <Col xs={1} className="text-start">
            {isOpen ? (
              <FaMinus
                color="#641FE3"
                cursor="pointer"
                onClick={() => setIsOpen(!isOpen)}
              />
            ) : (
              <FaPlus
                color="#641FE3"
                cursor="pointer"
                onClick={() => setIsOpen(!isOpen)}
              />
            )}
          </Col>

          <Col xs={10}>
            <Row>
              <Col xs={2} className="align-content-center">
                <ColContent isHoovered={hoovered}>
                  {longWordDisplay(invoice?.invoiceNumber)}
                </ColContent>
              </Col>
              <Col xs={2}>
                <ColContent isHoovered={hoovered}>
                  {longWordDisplay(invoice?.company.name)}
                </ColContent>
              </Col>
              <Col xs={2}>
                <ColContent isHoovered={hoovered}>
                  {longWordDisplay(invoice?.supplierName || supplier?.name)}
                </ColContent>
              </Col>
              <Col xs={2}>
                <ColContent isHoovered={hoovered}>
                  {`${invoice?.amountType === "$" ? "$" : "€"}` +
                    invoice?.amount}
                </ColContent>
              </Col>
              <Col xs={2}>
                <ColContent isHoovered={hoovered}>{invoice?.date}</ColContent>
              </Col>
            </Row>
          </Col>

          <Col xs={1} className="text-end">
            <ColContent>
              <FaTrash
                cursor="pointer"
                // onClick={() => removeInvoice(id)}
                onClick={() => {
                  openModal(id);
                }}
                color={hoovered ? "#641FE3" : "#DEDDDD"}
              />
            </ColContent>
          </Col>
        </Row>
      )}
      {!isUploaded && (
        <Row
          onMouseEnter={() => setHoovered(true)}
          onMouseLeave={() => setHoovered(false)}
        >
          <EmailModal
            show={showEmailModal}
            onHide={retrieveInvoices}
            message={emailMessage}
          />
          <Col xs={1} className="text-start">
            {isOpen ? (
              <FaMinus
                color="#641FE3"
                cursor="pointer"
                onClick={() => setIsOpen(!isOpen)}
              />
            ) : (
              <FaPlus
                color="#641FE3"
                cursor="pointer"
                onClick={() => setIsOpen(!isOpen)}
              />
            )}
          </Col>
          <Col xs={11}>
            <Row>
              <Col xs={2} className="align-content-center">
                <ColContent isHoovered={hoovered}>
                  {longWordDisplay(invoice_number)}
                </ColContent>
              </Col>
              <Col xs={2}>
                <ColContent isHoovered={hoovered}>
                  {longWordDisplay(company?.name)}
                </ColContent>
              </Col>
              <Col xs={2}>
                <ColContent isHoovered={hoovered}>
                  {longWordDisplay(supplier?.name)}
                </ColContent>
              </Col>
              <Col xs={2}>
                <ColContent isHoovered={hoovered}>
                  {`${invoice?.amountType === "&euro;" ? "€" : "$"}` +
                    invoice?.amount}
                </ColContent>
              </Col>
              <Col xs={2}>
                <ColContent isHoovered={hoovered}>{date}</ColContent>
              </Col>
              <Col xs={2} className="text-end">
                <ColContent isHoovered={hoovered}>
                  <ApprovalText onClick={() => setIsOpen(!isOpen)}>
                    {status}
                  </ApprovalText>

                  <RiArrowDropDownLine fontSize={20} />
                </ColContent>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {isOpen && !isUploaded && (
        <PdfViewerContainer width="50%">
          <PdfViewer base64Pdf={invoice?.pdfReport} />
          <Col
            xs={12}
            className="d-flex flex-column justify-content-center align-items-center mt-5 mb-3"
          >
            {/**** Title ****/}
            <>
              {status !== "fully_approved" &&
                status !== "partially_approved" &&
                status !== "declined" && (
                  <Col xs={12} className="mb-3">
                    <TitleApprove>Do you approve this invoice?</TitleApprove>
                  </Col>
                )}

              {status === "partially_approved" && user.id != userAccessId && (
                <Col xs={12} className="mb-3">
                  <TitleApprove>Do you approve this invoice?</TitleApprove>
                </Col>
              )}
            </>
            {/**** Notes ****/}
            <>
              {notes && (
                <Col xs={6} className="mb-3">
                  <Note>Note:</Note>
                  <Notes>{` ${notes}`}</Notes>
                </Col>
              )}
            </>

            {/**** Content ****/}
            <>
              {status !== "fully_approved" &&
                status !== "partially_approved" &&
                status !== "declined" && (
                  <Row>
                    <Col xs="auto">
                      <ConfirmButton
                        title="Approve"
                        styling="p-4 py-0 ms-4"
                        color="#6DC55F"
                        onClick={() => handleAcceptInvoice(id)}
                      />
                    </Col>
                    <Col xs="auto">
                      <ConfirmButton
                        title="Decline"
                        styling="p-4 py-0 me-4"
                        color="#F83232"
                        onClick={() => setShowModal(true)}
                      />
                    </Col>
                  </Row>
                )}

              {status === "fully_approved" && (
                <Row className="justify-content-start, align-center">
                  <Col xs={12} className="text-start">
                    <h5>Approved by:</h5>
                    {approved_users.map((i, key) => {
                      return (
                        <p key={key}>{i.email}</p>
                      )
                    })}
                  </Col>
                  <Col xs={12} className="text-start">
                    <h5>Date: {timeZoneFormat(updated_at)}</h5>
                  </Col>
                </Row>
              )}

              {status === "partially_approved" && user.id == userAccessId && (
                <Row className="justify-content-start">
                  <Col xs={12} className="text-start">
                    <h5>You already approved this invoice</h5>
                  </Col>
                </Row>
              )}
              {status === "partially_approved" && user.id != userAccessId && (
                <>
                  <Row>
                    {/* <Col xs={12}>"asd"</Col> */}
                    <Col xs="auto">
                      <ConfirmButton
                        title="Approve"
                        styling="p-4 py-0 ms-4"
                        color="#6DC55F"
                        onClick={() => handleAcceptInvoice(id)}
                      />
                    </Col>
                    <Col xs="auto">
                      <ConfirmButton
                        title="Decline"
                        styling="p-4 py-0 me-4"
                        color="#F83232"
                        onClick={() => setShowModal(true)}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-start ps-5 mt-3">
                    <Col xs={12} className="text-start">
                      <h5>Approved by:</h5>
                      {approved_users.map((i, key) => {
                        return (
                          <h5 key={key}>{i.email}</h5>
                        )
                      })}
                    </Col>
                    <Col xs={12} className="text-start">
                      <h5>Date: {timeZoneFormat(updated_at)}</h5>
                      {/* <h5>Time: {timeFormat(updated_at)} CEST</h5> */}
                    </Col>
                  </Row>
                </>
              )}

              {status === "declined" && (
                <Row className="justify-content-start">
                  <Col xs={12} className="text-start">
                    <h5>Invoice declined</h5>
                  </Col>
                </Row>
              )}
            </>
          </Col>
          {/* MODALs */}
          <>
            <DeclineModal
              handleSubmit={() => handleDeclineInvoice(id, declineMessage)}
              handleClose={handleClose}
              declineMessage={declineMessage}
              setDeclineMessage={setDeclineMessage}
              show={showModal}
            />
          </>
        </PdfViewerContainer>
      )}
      {isOpen && isUploaded && (
        <>
          <Row>
            <Col>
              <PdfViewerContainer width="100%">
                <PdfViewer base64Pdf={invoice?.pdfReport} />
              </PdfViewerContainer>
            </Col>
            <Col>
              <ApprovalSettings
                companies={companies}
                {...invoice}
                handleSubmitInvoice={handleSubmitInvoice}
                submitApprovalMessage={submitApprovalMessage}
                handleChange={handleChange}
                setIsOpen={setIsOpen}
                suppliers={suppliers}
                setSuppliers={setSuppliers}
                getSupplierId={getSupplierId}
                token={token}
                reload={reload}
                setReload={setReload}
                //**** Messages
                newSupplierMessage={newSupplierMessage}
                setNewSupplierMessage={setNewSupplierMessage}
                createInvoiceMessage={createInvoiceMessage}
                setCreateInvoiceMessage={setCreateInvoiceMessage}
                invoiceNumberMessage={invoiceNumberMessage}
                setInvoiceNumberMessage={setInvoiceNumberMessage}
                supplierNameMessage={supplierNameMessage}
                setSupplierNameMessage={setSupplierNameMessage}
                amountMessage={amountMessage}
                setAmountMessage={setAmountMessage}
              />
            </Col>
          </Row>
        </>
      )}
    </RowWrapper>
  );
};

export default React.memo(InvoiceRow);
