import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { ReactComponent as ReactLogo } from "../../assets/GroupApprovaLogo.svg";
// import { ReactComponent as ReactLogoTL } from "../../assets/GroupTopLeftLogo.svg";
// import { ReactComponent as ReactLogoBL } from "../../assets/GroupBottomLeftLogo.svg";
// import { ReactComponent as ReactLogoTR } from "../../assets/GroupTopRightLogo.svg";
// import { ReactComponent as ReactLogoBR } from "../../assets/GroupBottomRightLogo.svg";
import Ucoin from "../../assets/Ucoin_logo.png";
import UnboxTitle from "../../assets/Unbox_title.png";
import UniverseLoop from "../../assets/Universe_loop.png";
import SignInButton from "../Buttons/SignInButton";
import SignUpModal from "../Modal/SignUpModal";
import { Col, Row } from "react-bootstrap";

import { useMediaQuery } from "react-responsive";

// const fontFamilyUbuntu = css`
//   font-family: "Ubuntu";
// `;

// const Title = styled.h1`
//   ${fontFamilyUbuntu};
//   font-weight: bold;
//   font-size: 2.25rem;
//   color: #000000;
// `;

const LogoWrapper = styled.div`
  width: 100%;
  /* height: 6rem; */
`;

const Logo = styled.img`
  height: 100%;
  width: 100%;
  max-width: ${({ isTitle }) => (isTitle ? "30rem" : "6rem")};
`;

const SignUp = ({ login, showModal, handleClose, message }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 75rem)" });

  if (isTabletOrMobile) {
    return (
      <Row className="g-0" style={{ height: "100vh" }}>
        {/* Center */}
        <Col className="text-center">
          <Row className="g-0 d-flex align-items-center justify-content-center h-100">
            <Col className="align-items-center">
              <LogoWrapper>
                <Logo src={Ucoin} alt="ucoin" />
              </LogoWrapper>
              <LogoWrapper>
                <Logo src={UnboxTitle} alt="Unbox Invoices" isTitle={true} />
              </LogoWrapper>
              <SignInButton handleSignIn={login} />
            </Col>
          </Row>
        </Col>
        <SignUpModal
          showModal={showModal}
          handleClose={handleClose}
          message={message}
        />
      </Row>
    );
  } else {
    return (
      <Row className="g-0" style={{ height: "100vh" }}>
        {/* Left side */}
        <Col xs="4" className="d-flex flex-column justify-content-between">
          <img src={UniverseLoop} alt="Universe Loop" />
        </Col>
        {/* Center */}
        <Col xs={4} className="text-center">
          <Row className="g-0 d-flex align-items-center justify-content-center h-100">
            <Col className="align-items-center">
              <LogoWrapper>
                <Logo src={Ucoin} alt="ucoin" />
              </LogoWrapper>
              <LogoWrapper>
                <Logo src={UnboxTitle} alt="Unbox Invoices" isTitle={true} />
              </LogoWrapper>
              {isLoading ? (
                "Loading..."
              ) : (
                <SignInButton
                  handleSignIn={login}
                  setIsLoading={setIsLoading}
                />
              )}
            </Col>
          </Row>
        </Col>
        {/* Right side */}
        <Col xs="4" className="d-flex flex-column justify-content-end">
          <img src={UniverseLoop} alt="Universe Loop" />
        </Col>
        {/* Declined access modal */}
        <SignUpModal
          showModal={showModal}
          handleClose={handleClose}
          message={message}
        />
      </Row>
    );
  }
};

export default SignUp;
