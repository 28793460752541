import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LayoutWrapper from "../components/Layout/LayoutWrapper";
//import PageTitle from "../components/Titles/PageTitle";
import SearchBar from "../components/Search/SearchBar";
import Table from "../components/Table";
import ApiService from "../api/services";
import { getAccessToken } from "../utils/Auth";
import { ThreeDots } from "react-loader-spinner";
import TokenExpiredModal from "../components/Modal/TokenExpired";
import SwitchButton from "../components/Switch/SwitchButton";
import { getUserId } from "../utils/Auth";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import NavbarSettings from "../components/Navbar/NavbarSettings";

const Settings = () => {
  //   const userAccessId = getUserId("approval_user_id");

  const [isLoading, setIsLoading] = useState(false);

  return (
    <LayoutWrapper page="settings">
      <Container>
        <Row className="g-0 mt-5 pt-2 justify-content-between align-items-center">
          <NavbarSettings />
        </Row>

        {isLoading ? (
          <div className="w-100 d-flex flex-column align-items-center justify-content-center">
            <ThreeDots color="#641fe3" height="100" width="100" />
          </div>
        ) : (
          <Outlet />
        )}
        {/* <TokenExpiredModal show={showTokenExpiredModal} /> */}
      </Container>
    </LayoutWrapper>
  );
};

export default Settings;
