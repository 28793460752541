import React from "react";
import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import UserRow from "./UserRow";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const ColTitle = styled.span`
  ${fontFamilyUbuntu}
  font-weight: 500;
  font-size: 1rem;
  line-height: 21px;
  color: #a8a8a8;
`;

const ColTitleItem = ({ size, name, index }) => (
  <Col xs={size} className={`text-start ${index === 0 && "ps-3"}`}>
    <ColTitle>{name}</ColTitle>
  </Col>
);

const column_titles = [
  { name: "Name", size: 3 },
  { name: "Email", size: 4 },
  { name: "Role", size: 3 },
  { name: "", size: 1 },
];

const UsersTable = ({ invites, openEditModal, opedDeleteModal }) => {
  return (
    <>
      {/*** Titles ***/}
      <Row className="g-0" style={{ width: "90%" }}>
        {column_titles.map((item, index) => {
          return <ColTitleItem key={index} {...item} index={index} />;
        })}
      </Row>
      {/*** Rows ***/}
      {invites.map((invite, index) => {
        return (
          <UserRow
            key={index}
            {...invite}
            openEditModal={openEditModal}
            opedDeleteModal={opedDeleteModal}
          />
        );
      })}
    </>
  );
};

export default UsersTable;
