import React from "react";
import { Col, Container, Nav, Navbar, NavDropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import UcoinLogo from "../../assets/Ucoin_logo.png";
import styled, { css } from "styled-components";
import "./NavbarMain.css";
import { RiArrowDropDownLine } from "react-icons/ri";

import { useNavigate } from "react-router-dom";
import { useAuth, logOut, getIsAdmin } from "../../utils/Auth";

import { getUserName, getPermision } from "../../utils/Auth";

const fontFamilyUbuntuBold = css`
  font-family: "UbuntuBold";
`;

const fontFamilyRobotoBold = css`
  font-family: "RobotoBold";
`;

const Text = styled.span`
  ${fontFamilyRobotoBold};
  font-size: 1.125rem;
  color: ${({ color }) => color};
  &:hover {
    color: #641fe3;
    cursor: pointer;
  }
`;

const TextUserName = styled.span`
  ${fontFamilyRobotoBold};
  font-size: 1.125rem;
  color: ${({ color }) => color};
  &:hover {
    color: #641fe3;
    cursor: pointer;
  }
  text-transform: capitalize;
`;

const TextSecond = styled.span`
  ${fontFamilyUbuntuBold};
  font-weight: bold;
  font-size: 0.9rem;
  color: #000000;
  &:hover {
    color: #641fe3;
    cursor: pointer;
  }
`;

const Button = styled.button`
  border: none;
  background-color: white;
`;

const Logo = styled.img`
  width: 2.75rem;
`;

const separateUserName = (str) => {
  return str.split(" -")[0];
};

const NavbarMain = ({ page }) => {
  let userName = getUserName("approval_user_name");
  let isAdmin = getIsAdmin();

  let history = useNavigate();
  let auth = useAuth();

  return (
    <>
      <Navbar
        className="pt-3"
        style={{
          background: "#FFFFFF",
          boxShadow: "0px 1px 20px 1px rgba(162, 162, 162, 0.25)",
        }}
      >
        <Container fluid="md" className="">
          <Row className="w-100 justify-content-between">
            <Col sm="10">
              <Row>
                <Col sm="auto" className="">
                  <Navbar.Brand>
                    <Logo src={UcoinLogo} alt="Logo" />

                    {/* <LogoNavbar /> */}
                  </Navbar.Brand>
                </Col>
                <Col sm="auto" className="">
                  <Nav.Link as={Col} xs="auto" className="ps-sm-5 pe-sm-3 pe-0">
                    <Link to="/home" style={{ textDecoration: "none" }}>
                      <Text color={page === "home" ? "#641fe3" : "#000000"}>
                        SUPPLIER INVOICES
                      </Text>
                    </Link>
                  </Nav.Link>
                </Col>
                <Col sm="auto" className="">
                  <Nav.Link as={Col} xs="auto" className="">
                    <Link to="/uploaded" style={{ textDecoration: "none" }}>
                      <Text color={page === "uploaded" ? "#641fe3" : "#000000"}>
                        UPLOADED
                      </Text>
                    </Link>
                  </Nav.Link>
                </Col>
                <Col sm="auto">
                  <Nav.Link as={Col} xs="auto" className="">
                    <Link to="/suppliers" style={{ textDecoration: "none" }}>
                      <Text
                        color={page === "suppliers" ? "#641fe3" : "#000000"}
                      >
                        SUPPLIERS
                      </Text>
                    </Link>
                  </Nav.Link>
                </Col>
                {/* {isAdmin && (
                  <Col sm="auto">
                    <Nav.Link as={Col} xs="auto" className="">
                      <Link to="/settings" style={{ textDecoration: "none" }}>
                        <Text
                          color={page === "settings" ? "#641fe3" : "#000000"}
                        >
                          SETTINGS
                        </Text>
                      </Link>
                    </Nav.Link>
                  </Col>
                )} */}
              </Row>
            </Col>
            <Col sm="2" className="">
              <Navbar.Collapse
                as={Row}
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <NavDropdown
                  className=""
                  as={Col}
                  xs="auto"
                  title={
                    <TextUserName color="#000000">
                      {separateUserName(
                        userName || "dan burghelea - unbox universe"
                      )}
                    </TextUserName>
                  }
                >
                  {isAdmin && (
                    <NavDropdown.Item as={Col}>
                      <Button
                        onClick={() => {
                          localStorage.setItem("settings_tab", "users");
                          history("/settings/users");
                        }}
                      >
                        <TextSecond>Settings</TextSecond>
                      </Button>
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item as={Col}>
                    <Button
                      onClick={() => {
                        logOut(() => history("/"));
                      }}
                    >
                      <TextSecond>Log out</TextSecond>
                    </Button>
                  </NavDropdown.Item>
                </NavDropdown>
                <RiArrowDropDownLine
                  fontSize="1.5rem"
                  style={{ marginLeft: "-1rem", marginTop: "0.2rem" }}
                />
              </Navbar.Collapse>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarMain;
