import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import styled, { css } from "styled-components";
import ConfirmButton from "../Buttons/ConfirmButton";
import RoundedInput from "../Input/RoundedInput";
import CustomSelect from "../Select/CustomSelect";
import CheckSelect from "../Select/CheckSelect";
import SwitchButton from "../Switch/SwitchButton";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const Text = styled.div`
  ${fontFamilyUbuntu};
  font-weight: bold;
  font-size: 1.25rem;
  color: #000000;
  text-align: ${({ isSuppliersPopup }) =>
    isSuppliersPopup ? "center" : "left"};
  margin: auto;
  margin-left: 2rem;
  @media (max-width: 75rem) {
    font-size: 1.25rem;
    text-align: center;
  }
  @media (max-width: 28.125rem) {
    font-size: 1.125rem;
    text-align: center;
  }
`;

const OptionsLabel = styled.span`
  ${fontFamilyUbuntu}
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 16.09px;
  color: #000000;
  margin-left: 1rem;
  margin-bottom: 0.25rem;
`;

const SelectorContainer = styled.div`
  margin-top: 0.5rem;
  width: 100%;
`;

const LabelStartPosition = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

const InviteUserModal = ({
  showModal,
  handleClose,
  handleSubmit,
  title,
  email,
  handleEmailChange,
  role,
  roles,
  setRole,
  companies,
  selectedCompanies,
  setSelectedCompanies,
  isEdit,
}) => {
  // const [selectCompanies, setSelectCompanies] = useState([]);
  // const [isUserEnabled, setIsUserEnabled] = useState(false);

  // const handleUserActivation = () => {
  //   setIsUserEnabled(!isUserEnabled);
  // };

  return (
    <>
      <Modal show={showModal} size="md" onHide={handleClose}>
        <ModalHeader className="">
          <Row className="g-0 w-100">
            <Col xs={6} className="d-flex flex-column align-items-start mt-2">
              <Text className="my-xl-1 my-1 p-0">{title}</Text>
            </Col>
          </Row>
        </ModalHeader>
        <Modal.Body className={`p-3 px-0 text-xl-center text-center`}>
          <Row className="g-0 justify-content-center">
            {/* {title === "Edit User" && (
              <Col
                xs={8}
                className="d-flex flex-column align-items-end mt-4 mb-4"
              >
                <SwitchButton
                  title="Enable/Disable User"
                  handleChange={handleUserActivation}
                  checked={isUserEnabled}
                  isSettingScreen={true}
                />
              </Col>
            )} */}
            {/*** Email ***/}
            <>
              <Col xs={8} className="d-flex flex-column align-items-start mt-2">
                <LabelStartPosition>
                  <OptionsLabel>Email *</OptionsLabel>
                </LabelStartPosition>
                <SelectorContainer>
                  <RoundedInput
                    value={email}
                    onChange={handleEmailChange}
                    readOnly={isEdit}
                  />
                </SelectorContainer>
              </Col>
            </>
            {/*** Role ***/}
            <>
              <Col xs={8} className="d-flex flex-column align-items-start mt-4">
                <LabelStartPosition>
                  <OptionsLabel>Role *</OptionsLabel>
                </LabelStartPosition>
                <SelectorContainer>
                  <CustomSelect
                    items={roles}
                    selectedItem={role}
                    setSelectedItem={setRole}
                  />
                </SelectorContainer>
              </Col>
            </>
            {/*** Companies ***/}
            <>
              <Col xs={8} className="d-flex flex-column align-items-start mt-4">
                <LabelStartPosition>
                  <OptionsLabel>Company/Companies *</OptionsLabel>
                </LabelStartPosition>
                <SelectorContainer>
                  <CheckSelect
                    isInviteModal={true}
                    isEdit={isEdit}
                    items={companies}
                    selectedItems={selectedCompanies}
                    setSelectedItems={setSelectedCompanies}
                  />
                </SelectorContainer>
              </Col>
            </>

            {/*** Button ***/}
            <>
              <Col xs={10} className="text-end">
                <ConfirmButton
                  styling={`mb-xl-2 mt-5 py-1`}
                  title={title === "New User" ? "Send invitation" : "Edit user"}
                  onClick={handleSubmit}
                />
              </Col>
            </>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InviteUserModal;
