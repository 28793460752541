import React from "react";
import { Modal } from "react-bootstrap";
import styled, { css } from "styled-components";
import ConfirmButton from "../Buttons/ConfirmButton";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const Text = styled.div`
  ${fontFamilyUbuntu};
  font-weight: bold;
  font-size: 1.625rem;
  color: #000000;
  text-align: "center";
  margin: auto;
  @media (max-width: 75rem) {
    font-size: 1.25rem;
    text-align: center;
  }
  @media (max-width: 28.125rem) {
    font-size: 1.125rem;
    text-align: center;
  }
`;

const SignUpModal = ({ showModal, message }) => {
  return (
    <>
      <Modal show={showModal} centered size="md">
        <Modal.Body className="p-4 text-xl-center text-center">
          <Text className="my-xl-5 my-4">{message}</Text>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SignUpModal;
