import styled from "styled-components";

const Switch = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 100%;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 1px solid #641fe3;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1rem;
    width: 1rem;
    left: 3px;
    bottom: 3px;
    background-color: #641fe3;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #641fe3;
    border: 1px solid white;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(1rem);
    -ms-transform: translateX(1rem);
    transform: translateX(1rem);
    background-color: white;
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: 2.125rem;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

const SwitchButton = ({ handleChange, checked, title, isSettingScreen }) => {
  return (
    <Switch>
      {isSettingScreen ? (
        <>
          {` ${title}`}
          <label className="switch ms-2">
            <input type="checkbox" checked={checked} onChange={handleChange} />
            <span className="slider round"></span>
          </label>
        </>
      ) : (
        <>
          <label className="switch">
            <input type="checkbox" checked={checked} onChange={handleChange} />
            <span className="slider round"></span>
          </label>
          {` ${title}`}
        </>
      )}
    </Switch>
  );
};

export default SwitchButton;
