import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AddRoleButton from "../../components/Buttons/ConfirmButton";
import CheckDeleteModal from "../../components/Modal/CheckDeleteModal";
import RoleModal from "../../components/Modal/RoleModal";
import TokenExpiredModal from "../../components/Modal/TokenExpired";
import RolesTable from "../../components/Table/RolesTable";
import { getAccessToken } from "../../utils/Auth/index";
import ApiService from "../../api/services";
import { ThreeDots } from "react-loader-spinner";

const RolesPermissions = () => {
  const token = getAccessToken();

  // *** Roles *** //
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState("");

  // *** Description *** //
  const [description, setDescription] = useState("");

  // *** Permissions *** //
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermisions] = useState([]);
  const [selectedPermissionsIDs, setSelectedPermisionsIDs] = useState([]);

  // *** Booleans *** //
  const [isAddRoleModal, setIsAddRoleModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTokenExpiredModal, setShowTokenExpiredModal] = useState(false);

  // *** Value changing / Modal functions *** //

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handlOpenEditModal = (id) => {
    const filteredRole = roles.filter((role) => role.id === id);
    const role = filteredRole[0].name;
    const desc = filteredRole[0].description;
    const perm = filteredRole[0].permissions.reduce((total, curr) => {
      total.push({ id: curr.id, name: curr.name });
      return total;
    }, []);

    setSelectedPermisions(perm);
    setSelectedRoleId(filteredRole[0].id);
    setRole(role);
    setDescription((prev) => desc || prev);
    setIsEditModal(true);
  };

  const handlOpenDeleteModal = (id) => {
    const filteredRole = roles.filter((role) => role.id === id);
    setSelectedRoleId(filteredRole[0].id);
    setIsDeleteModal(true);
  };

  const handleCloseModal = (setIsSecondStep) => {
    setIsEditModal(false);
    setIsAddRoleModal(false);
    setSelectedRoleId("");
    setRole("");
    setDescription("");
    setSelectedPermisions([]);
    setSelectedPermisionsIDs([]);
    if (setIsSecondStep) {
      setIsSecondStep(false);
    }
  };

  // *** API functions *** //
  const retrieveRoles = useCallback(async () => {
    setIsLoading(true);
    try {
      let res = await ApiService.getRoles(token);

      if (res && !res.isAxiosError) {
        const { data } = { ...res };
        setRoles(data?.data);
      } else if (res.isAxiosError) {
        let response = res.response.statusText;
        if (response === "Unauthorized") {
          //token expired, redirect to login
          setShowTokenExpiredModal(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }, [token]);

  const retrievePermissions = useCallback(async () => {
    setIsLoading(true);
    try {
      let res = await ApiService.getPermissions(token);

      if (res && !res.isAxiosError) {
        const { data } = { ...res };
        setPermissions(data?.data);
      } else if (res.isAxiosError) {
        let response = res.response.statusText;
        if (response === "Unauthorized") {
          //token expired, redirect to login
          setShowTokenExpiredModal(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }, [token]);

  const createRole = useCallback(
    async (name, desc, perm) => {
      try {
        let res = await ApiService.createRole(token, name, desc, perm);

        if (res && !res.isAxiosError) {
          handleCloseModal();
          retrieveRoles();
        } else if (res.isAxiosError) {
          let response = res.response.statusText;
          if (response === "Unauthorized") {
            //token expired, redirect to login
            setShowTokenExpiredModal(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [token, retrieveRoles]
  );

  const editRole = useCallback(
    async (id, name, description, perm) => {
      try {
        let res = await ApiService.editRole(token, id, name, description, perm);

        if (res && !res.isAxiosError) {
          setIsEditModal(false);
          retrieveRoles();
        } else if (res.isAxiosError) {
          let response = res.response.statusText;
          if (response === "Unauthorized") {
            //token expired, redirect to login
            setShowTokenExpiredModal(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [token, retrieveRoles]
  );

  const deleteRole = useCallback(
    async (id) => {
      try {
        let res = await ApiService.deleteRole(token, id);

        if (res && !res.isAxiosError) {
          setIsDeleteModal(false);
          retrieveRoles();
        } else if (res.isAxiosError) {
          let response = res.response.statusText;
          if (response === "Unauthorized") {
            //token expired, redirect to login
            setShowTokenExpiredModal(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [token, retrieveRoles]
  );

  // *** Submit functions *** //

  const handleCreateRoleSubmit = () => {
    createRole(role, description, selectedPermissionsIDs);
  };
  const handleEditRoleSubmit = () => {
    editRole(selectedRoleId, role, description, selectedPermissionsIDs);
  };
  const handleDeleteRoleSubmit = () => {
    deleteRole(selectedRoleId);
  };

  useEffect(() => {
    if (token && !showTokenExpiredModal) {
      retrieveRoles();
      retrievePermissions();
    }
  }, [token, showTokenExpiredModal, retrieveRoles, retrievePermissions]);

  useEffect(() => {
    setSelectedPermisionsIDs(() => {
      const newValues = selectedPermissions.reduce((total, curr) => {
        total.push(curr.id);
        return total;
      }, []);
      return newValues;
    });
  }, [selectedPermissions]);

  return (
    <>
      <Row className="mt-4">
        <Col xs={1} className="me-4"></Col>
        <>
          {isLoading ? (
            <>
              <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                <ThreeDots color="#641fe3" height="100" width="100" />
              </div>
            </>
          ) : (
            <>
              <Col xs={11} className="text-end">
                <AddRoleButton
                  onClick={() => setIsAddRoleModal(true)}
                  title="Add role"
                />
              </Col>
              <Row className="g-0">
                <Col xs={1} className="me-4"></Col>
                <Col className="mt-4 ps-3">
                  <RolesTable
                    roles={roles}
                    openEditModal={handlOpenEditModal}
                    opedDeleteModal={handlOpenDeleteModal}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
        {/*****  Modals *****/}
        <>
          {/* Add role */}
          <RoleModal
            showModal={isAddRoleModal}
            handleClose={handleCloseModal}
            handleSubmit={handleCreateRoleSubmit}
            title="New Role"
            isAddRole={true}
            role={role}
            description={description}
            handleRoleChange={handleRoleChange}
            handleDescriptionChange={handleDescriptionChange}
            permissions={permissions}
            selectedPermissions={selectedPermissions}
            setSelectedPermisions={setSelectedPermisions}
          />
          {/* Edit Role */}
          <RoleModal
            showModal={isEditModal}
            handleClose={handleCloseModal}
            handleSubmit={handleEditRoleSubmit}
            title="Edit Role"
            role={role}
            description={description}
            handleRoleChange={handleRoleChange}
            handleDescriptionChange={handleDescriptionChange}
            permissions={permissions}
            selectedPermissions={selectedPermissions}
            setSelectedPermisions={setSelectedPermisions}
          />
          {/* Delete role */}
          <CheckDeleteModal
            show={isDeleteModal}
            handleClose={() => setIsDeleteModal(false)}
            handleSubmit={handleDeleteRoleSubmit}
            toBeDeleted="role"
          />
          {/* Token */}
          <TokenExpiredModal show={showTokenExpiredModal} />
        </>
      </Row>
    </>
  );
};

export default RolesPermissions;
