import React, { useState, useEffect, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import LayoutWrapper from "../components/Layout/LayoutWrapper";
import SearchBar from "../components/Search/SearchBar";
import ConfirmButton from "../components/Buttons/ConfirmButton";
import Table from "../components/Table";
import ApiService from "../api/services";
import { getAccessToken } from "../utils/Auth";
import { ThreeDots } from "react-loader-spinner";
import TokenExpiredModal from "../components/Modal/TokenExpired";
import NewSupplierModal from "../components/Modal/NewSupplierModal";
import ConfirmationPopup from "../components/Modal/ConfirmationPopup";
import { msgNewSupplierEmptyFields, msgSupplierExist } from "../utils/Messages";

const Suppliers = () => {
  const token = getAccessToken();

  // *** Suppliers *** //

  const [suppliers, setSuppliers] = useState([]);

  // *** Companies *** //

  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedCompaniesIDs, setSelectedCompaniesIDs] = useState([]);

  // *** Search *** //

  const [search, setSearch] = useState("");

  // ** Pagination States ** //

  const [actualPageNumber, setActualPageNumber] = useState(1);
  const [lastPageNumber, setLastPageNumber] = useState("");
  const [from, setFrom] = useState("");

  // ** Booleans ** //

  const [isLoading, setIsLoading] = useState(false);
  const [isPaginationVisible, setIsPaginationVisible] = useState(false);
  const [showTokenExpiredModal, setShowTokenExpiredModal] = useState(false);
  const [isCreateSupplierModal, setIsCreateSupplierModal] = useState(false);
  const [isInfoCreatePopupOpen, setIsInfoCreatePopupOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isInfoUpdatePopupOpen, setIsInfoUpdatePopupOpen] = useState(false);

  // *** Values *** //
  const [supplierName, setSupplierName] = useState("");
  const [selectedSupplierType, setSelectedSupplierType] = useState("");
  const [message, setMessage] = useState("");

  // console.log(supplierName);
  // console.log(selectedSupplierType);
  // console.log(companies);
  // console.log(selectedCompaniesIDs);
  const supplierTypes = ["Regular", "Exception"];

  // *** Value changing / Modal functions *** //

  const handleSearch = (e) => {
    // setActualPageNumber(1);
    let input = e.target.value;
    setSearch(input);

    if (input.length >= 3) {
      retrieveSuppliers(1, input);
    } else if (input.length === 0) {
      retrieveSuppliers(1, "");
    } else {
      return;
    }
  };
  const handleSupplierNameChange = (e) => {
    setSupplierName(e.target.value);
  };
  const handleOpenModal = () => {
    setIsCreateSupplierModal(true);
  };
  const handleCloseModal = () => {
    setIsCreateSupplierModal(false);
    setSupplierName("");
    setSelectedSupplierType("");
    setSelectedCompaniesIDs("");
    setSelectedCompanies([]);
    setMessage("");
  };

  // *** API functions *** //

  const retrieveCompanies = useCallback(async () => {
    try {
      let res = await ApiService.getCompanies(token);

      if (res && !res.isAxiosError) {
        const { data } = { ...res };
        setCompanies(data?.data);
      } else if (res.isAxiosError) {
        let response = res.response.statusText;
        if (response === "Unauthorized") {
          //token expired, redirect to login
          setShowTokenExpiredModal(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  const retrieveSuppliers = useCallback(
    async (page, search) => {
      setIsLoading(true);
      try {
        let res = await ApiService.getAllSuppliers(token, page, search);
        if (res && !res.isAxiosError) {
          const { data } = { ...res };
          setFrom(data.meta.from);
          if (data.meta.last_page === 1) {
            setIsPaginationVisible(false);
          } else {
            setIsPaginationVisible(true);
          }
          setActualPageNumber(data.meta.current_page);
          setLastPageNumber(data.meta.last_page);
          setSuppliers(data?.data);
        } else if (res.isAxiosError) {
          let response = res.response.statusText;
          if (response === "Unauthorized") {
            //token expired, redirect to login
            setShowTokenExpiredModal(true);
          }
        }
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    },
    [token]
  );

  const addSupplier = async (newSupplier, selectedSupplierType, companies) => {
    if (newSupplier && selectedSupplierType) {
      try {
        let res = await ApiService.createSupplier(
          token,
          newSupplier,
          selectedSupplierType,
          companies
        );

        if (res && !res.isAxiosError) {
          handleCloseModal();
          setSearch("");
          setIsInfoCreatePopupOpen(true);
          setTimeout(() => {
            setIsInfoCreatePopupOpen(false);
            retrieveSuppliers(1, "");
          }, 2000);
        } else if (res && res.isAxiosError) {
          setMessage(
            res.response.data.error === "The given data was invalid." &&
              msgSupplierExist
          );
          setTimeout(() => {
            setMessage("");
          }, 2000);
        }
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    } else {
      setMessage(msgNewSupplierEmptyFields);
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  };

  const updateSupplier = async (id, name, type, companies) => {
    try {
      let res = await ApiService.updateSupplier(
        token,
        id,
        name,
        type,
        companies
      );
      if (res && !res.isAxiosError) {
        // const { data } = { ...res };
        setIsInfoUpdatePopupOpen(true);
        setTimeout(() => {
          setIsInfoUpdatePopupOpen(false);
          retrieveSuppliers(1, "");
        }, 2000);
      } else if (res.isAxiosError) {
        let response = res.response.statusText;
        if (response === "Unauthorized") {
          //token expired, redirect to login
          setShowTokenExpiredModal(true);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const requestIncrementPage = () => {
    const searchBar = search ? search : "";
    if (actualPageNumber + 1 > lastPageNumber) {
      retrieveSuppliers(1, searchBar);
    } else {
      retrieveSuppliers(actualPageNumber + 1, searchBar);
    }
  };

  const requestDecrementPage = () => {
    const searchBar = search ? search : "";
    if (actualPageNumber - 1 <= 0) {
      retrieveSuppliers(lastPageNumber, searchBar);
    } else {
      retrieveSuppliers(actualPageNumber - 1, searchBar);
    }
  };

  // *** Submit functions *** //

  const handleCreateSupplier = () => {
    addSupplier(supplierName, ...selectedSupplierType, selectedCompaniesIDs);
  };

  useEffect(() => {
    if (token && !showTokenExpiredModal) {
      retrieveSuppliers(1, "");
      retrieveCompanies();
    }
  }, [token, showTokenExpiredModal, retrieveSuppliers, retrieveCompanies]);

  useEffect(() => {
    setSelectedCompaniesIDs(() => {
      const newValues = selectedCompanies.reduce((total, curr) => {
        total.push(curr.id);
        return total;
      }, []);
      return newValues;
    });
  }, [selectedCompanies]);

  return (
    <LayoutWrapper page="suppliers">
      <Row className="g-0 mt-5 pt-2 justify-content-between">
        <Col xs={3}></Col>
        <Col xs={4} className="text-center pe-5">
          <SearchBar wd="20vw" search={search} handleSearch={handleSearch} />
        </Col>
        <Col xs={3}>
          <ConfirmButton
            title="Add new"
            styling="px-4 py-1"
            onClick={handleOpenModal}
          />
        </Col>
      </Row>
      {/***** Main Content *****/}
      <>
        {isLoading ? (
          /***** Loading ... *****/
          <>
            <div className="w-100 d-flex flex-column align-items-center justify-content-center">
              <ThreeDots color="#641fe3" height="100" width="100" />
            </div>
          </>
        ) : (
          /***** Table Wrap *****/
          <>
            {/***** Table *****/}
            <>
              <Table
                isInvoicesTable={false}
                isPaginationVisible={isPaginationVisible}
                from={from}
                token={token}
                suppliers={suppliers}
                setSuppliers={setSuppliers}
                search={search}
                addSupplier={addSupplier}
                actualPageNumber={actualPageNumber}
                setActualPageNumber={setActualPageNumber}
                lastPageNumber={lastPageNumber}
                setLastPageNumber={setLastPageNumber}
                paginationRequests={{
                  requestIncrementPage,
                  requestDecrementPage,
                }}
                updateSupplier={updateSupplier}
                retrieveSuppliers={retrieveSuppliers}
                isInfoUpdatePopupOpen={isInfoUpdatePopupOpen}
                companies={companies}
              />
            </>
            {/***** Modals *****/}
            <>
              <NewSupplierModal
                show={isCreateSupplierModal}
                message={message}
                handleClose={handleCloseModal}
                handleSubmit={handleCreateSupplier}
                supplierName={supplierName}
                setSupplierName={handleSupplierNameChange}
                supplierTypes={supplierTypes}
                selectedSupplierType={selectedSupplierType}
                setSelectedSupplierType={setSelectedSupplierType}
                submitting={submitting}
                companies={companies}
                selectedCompanies={selectedCompanies}
                setSelectedCompanies={setSelectedCompanies}
              />
              <ConfirmationPopup
                showModal={isInfoCreatePopupOpen}
                message="Supplier successfully created"
              />
            </>
          </>
        )}
      </>
      <TokenExpiredModal show={showTokenExpiredModal} />
    </LayoutWrapper>
  );
};

export default Suppliers;
