import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import WelcomePage from "./pages/SignUp";
import HomePage from "./pages/Home";
import SuppliersPage from "./pages/Suppliers";
import SuppliersExceptionsPage from "./pages/SuppliersExceptions";
import UploadedPage from "./pages/Uploaded";
import NotFoundPage from "./pages/NotFound";
import Settings from "./pages/Settings";
import Users from "./pages/SettingsSubpages/InviteUsers";
import RolesPermissions from "./pages/SettingsSubpages/RolesPermissions";
import { ProvideAuth, PrivateRoute, getPermision } from "./utils/Auth";

function App() {
  // let isAdmin = getPermision("approval_admin_role");
  let isAdmin = true;

  return (
    <>
      <ProvideAuth>
        <Router>
          <Routes>
            <Route
              exact
              path="/home"
              element={
                <PrivateRoute>
                  <HomePage />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/uploaded"
              element={
                <PrivateRoute>
                  <UploadedPage />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/suppliers"
              element={
                <PrivateRoute>
                  <SuppliersPage />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/supplier-exceptions"
              element={
                <PrivateRoute>
                  <SuppliersExceptionsPage />
                </PrivateRoute>
              }
            />
            {isAdmin && (
              <Route
                // exact
                path="settings"
                element={
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                }
              >
                <Route
                  // exact
                  path="users"
                  element={
                    <PrivateRoute>
                      <Users />
                    </PrivateRoute>
                  }
                />
                <Route
                  // exact
                  path="roles"
                  element={
                    <PrivateRoute>
                      <RolesPermissions />
                    </PrivateRoute>
                  }
                />
              </Route>
            )}
            <Route exact path="/" element={<WelcomePage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </ProvideAuth>
    </>
  );
}

export default App;
