import React from "react";
import styled from "styled-components";

const InputContainer = styled.input`
  border: 2px solid #ebebeb;
  border-radius: 25px;
  width: 100%;
  height: 2rem;
  color: #5e5c5c;
  text-align: center;
  &:focus-visible {
    outline-style: none;
  }
`;

const TextareaContainer = styled.textarea`
  border: 2px solid #ebebeb;
  border-radius: 25px;
  width: 100%;
  height: 6rem;
  color: #5e5c5c;
  text-align: left;
  resize: none;
  transition: height 0.3s;
  overflow: hidden;
  padding: 0.25rem 1.25rem;
  &::placeholder {
    text-align: center;
  }
  /* &:focus-visible {
    outline-style: none;
    height: 4rem;
    transition: height 0.3s;
  } */
`;

const Input = ({
  onChange,
  placeholder,
  value,
  isSecure,
  id,
  onClick,
  isTextArea,
  type,
  readOnly,
}) => {
  if (isTextArea) {
    return (
      <TextareaContainer
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
    );
  }

  return (
    <InputContainer
      type={type}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      isSecure={isSecure}
      id={id}
      onClick={onClick}
      readOnly={readOnly}
    />
  );
};

export default Input;
