import { Col, Modal, Row } from "react-bootstrap";
import ConfirmButton from "../Buttons/ConfirmButton";
import styled from "styled-components";
import { useState } from "react";

const Title = styled.span`
  font-family: Ubuntu;
  font-weight: bold;
  font-size: 1.5rem;
  color: #000000;
`;
const TextArea = styled.textarea`
  width: 100%;
  height: 6rem;
  resize: none;
  border: 2px solid #ebebeb;
  border-radius: 10px;
  padding: 0.5rem 1rem;
`;

const DeclineModal = ({
  show,
  handleClose,
  handleSubmit,
  declineMessage,
  setDeclineMessage,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <Row className="justify-content-end p-4">
          <Col xs={12} className="text-start">
            <Title>Why is this invoice declined?</Title>
          </Col>
          <Col xs={12} className="text-center mt-4 mb-3">
            <TextArea
              value={declineMessage}
              onChange={(e) => setDeclineMessage(e.target.value)}
            />
          </Col>
          <Col xs="auto">
            <ConfirmButton
              title="Close"
              styling="py-1"
              color="#DEDDDD"
              borderColor="transparent"
              onClick={() => {
                handleClose();
                setDeclineMessage("");
              }}
            />
          </Col>
          <Col xs="auto">
            <ConfirmButton title="OK" styling="py-1" onClick={handleSubmit} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default DeclineModal;
