import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import ConfirmButton from "../Buttons/ConfirmButton";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const ModalText = styled.span`
  ${fontFamilyUbuntu};
  font-size: 2rem;
  /* text-align: center; */
`;

const EmailModal = (props) => {
  const isLongText = props.message.includes("You approved the invoice");

  return (
    <Modal
      {...props}
      size={isLongText ? "lg" : "md"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body>
        <Row>
          {/* <Col xs={12}>
            <h4>Centered Modal</h4>
          </Col> */}
          <Col xs={12} className="text-center py-3">
            <ModalText>{props.message}</ModalText>
          </Col>
          <Col xs={12} className="text-center pb-2 pt-4">
            <ConfirmButton onClick={props.onHide} title="OK" />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default EmailModal;
