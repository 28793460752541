import { Col, Modal, Row } from "react-bootstrap";
import ConfirmButton from "../Buttons/ConfirmButton";
import styled from "styled-components";

const Title = styled.span`
  font-family: Ubuntu;
  font-weight: bold;
  font-size: 1.15rem;
  color: #000000;
`;

const CheckDeleteModal = ({ show, handleClose, handleSubmit, toBeDeleted }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <Row className="justify-content-center p-4">
          <Col xs={12} className="text-center">
            <Title>Are you sure you want to delete this {toBeDeleted}?</Title>
          </Col>
          <Col xs={12} className="text-center mt-4 mb-3"></Col>
          <Col xs="auto">
            <ConfirmButton
              title="Yes"
              styling="py-1"
              color="#DEDDDD"
              borderColor="transparent"
              onClick={handleSubmit}
            />
          </Col>
          <Col xs="auto">
            <ConfirmButton
              title="No"
              styling="py-1"
              onClick={() => {
                handleClose();
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CheckDeleteModal;
