import { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Selector from "../Select";
import styled, { css } from "styled-components";
import { FaPlus, FaMinus, FaTrash } from "react-icons/fa";
import RoundedInput from "../Input/RoundedInput";
import Button from "../Buttons/ConfirmButton";
import CustomSelect from "../Select/CustomSelect";
import CheckSelect from "../Select/CheckSelect";
// import CheckDeleteModal from "../Modal/CheckDeleteModal";

const RowWrapper = styled.div`
  text-align: center;
  border-bottom: ${(props) => (props.isHoovered ? "2px" : "1px")} solid;
  border-bottom-color: ${(props) => (props.isHoovered ? "#641FE3" : "#DEDDDD")};
  padding-bottom: 0.5rem;
  padding-top: 1.5rem;
`;

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const ColContent = styled.span`
  ${fontFamilyUbuntu}
  font-weight: ${(props) => (props.isHoovered ? "700" : "400")};
  font-size: 1.125rem;
  line-height: 21px;
  color: ${(props) => (props.isHoovered ? "#641FE3" : "#5E5C5C")};
`;

const OptionsLabel = styled.span`
  ${fontFamilyUbuntu}
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 16.09px;
  color: #000000;
`;

const SelectorContainer = styled.div`
  margin-top: 0.5rem;
  width: 100%;
`;

const LabelStartPosition = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

const SupplierRow = ({
  index,
  id,
  name,
  type,
  openModal,
  updateSupplier,
  companiesTotal,
  companies,
  // selectedCompanies,
  // setSelectedCompanies,
  // selectedCompaniesIDs,
}) => {
  const [hoovered, setHoovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [actualType, setActualType] = useState(type);
  const [typeSubmitted, setTypeSubmitted] = useState(type);

  const [customName, changeName] = useState(name);
  const [nameSubmitted, setNameSubmitted] = useState(name);

  // Companies

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedCompaniesIDs, setSelectedCompaniesIDs] = useState([]);

  const types = ["Regular", "Exception"];

  // Row open/close
  const openRow = () => {
    setSelectedCompanies(companies);
    setIsOpen(true);
  };
  const closeRow = () => {
    setSelectedCompanies([]);
    setIsOpen(false);
  };

  const handleChange = (e) => {
    changeName(e.target.value);
  };

  useEffect(() => {
    setTypeSubmitted(actualType);
  }, [actualType]);

  useEffect(() => {
    setSelectedCompaniesIDs(() => {
      const newValues = selectedCompanies.reduce((total, curr) => {
        total.push(curr.id);
        return total;
      }, []);
      return newValues;
    });
  }, [selectedCompanies]);

  // TO SEND ON API
  // console.log(actualType.toString());
  // console.log(customName);
  console.log(selectedCompaniesIDs);
  return (
    <>
      <RowWrapper isHoovered={hoovered}>
        <Row
          onMouseEnter={() => setHoovered(true)}
          onMouseLeave={() => setHoovered(false)}
        >
          <Col xs={1}>
            {isOpen ? (
              <FaMinus color="#641FE3" cursor="pointer" onClick={closeRow} />
            ) : (
              <FaPlus color="#641FE3" cursor="pointer" onClick={openRow} />
            )}
          </Col>
          <Col xs={2} className="align-content-center">
            <ColContent isHoovered={hoovered}>{index + 1}</ColContent>
          </Col>
          <Col xs={4}>
            <ColContent isHoovered={hoovered}>{nameSubmitted}</ColContent>
          </Col>
          <Col xs={4}>
            <ColContent isHoovered={hoovered}>{typeSubmitted}</ColContent>
          </Col>
          <Col xs={1}>
            <ColContent>
              <FaTrash
                cursor="pointer"
                onClick={() => openModal(id)}
                color={hoovered ? "#641FE3" : "#DEDDDD"}
              />
            </ColContent>
          </Col>
        </Row>
      </RowWrapper>

      {isOpen && (
        <Row className="mt-4 mb-4 p-5">
          <Col>
            <Row className="d-flex flex-row justify-content-start mt-2 mb-5">
              <Col xs={5} className="d-flex flex-column align-items-start">
                <LabelStartPosition>
                  <OptionsLabel className="ps-3 mb-2">
                    Supplier Name
                  </OptionsLabel>
                </LabelStartPosition>
                <SelectorContainer>
                  <RoundedInput value={customName} onChange={handleChange} />
                </SelectorContainer>
              </Col>
              <Col xs={5} className="d-flex flex-column align-items-start">
                <LabelStartPosition>
                  <OptionsLabel className="ps-3 mb-2">
                    Supplier Type
                  </OptionsLabel>
                </LabelStartPosition>
                <SelectorContainer>
                  <CustomSelect
                    isSupplierPage={true}
                    items={types}
                    selectedItem={actualType}
                    setSelectedItem={setActualType}
                  />
                </SelectorContainer>
              </Col>
              <Col xs={5} className="d-flex flex-column align-items-start">
                <LabelStartPosition>
                  <OptionsLabel className="ps-3 mb-2">Companies</OptionsLabel>
                </LabelStartPosition>
                <SelectorContainer>
                  <CheckSelect
                    isEdit={true}
                    items={companiesTotal}
                    selectedItems={selectedCompanies}
                    setSelectedItems={setSelectedCompanies}
                  />
                </SelectorContainer>
              </Col>
            </Row>

            <Row>
              {/* <Col className="d-flex flex-column align-items-start" /> */}

              <Col xs={10} className="d-flex flex-column align-items-end pe-4">
                <Button
                  title="Save"
                  styling="py-1"
                  onClick={() =>
                    updateSupplier(
                      id,
                      customName,
                      actualType.toString(),
                      selectedCompaniesIDs
                    )
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      )}
    </>
  );
};

export default SupplierRow;
