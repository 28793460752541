import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { RiArrowDropDownLine } from "react-icons/ri";

const SelectWrapper = styled.div`
  position: relative;
  cursor: pointer;
  .arrow-down {
    font-size: 2rem;
    position: absolute;
    right: 0;
    color: #cccccc;
    margin-right: 0.3rem;
  }
`;
const SelectInput = styled.input`
  border: 2px solid #ebebeb;
  border-radius: 25px;
  background: white;
  width: 100%;
  height: 2rem;
  /* font-weight: 500; */
  /* font-size: 1rem; */
  text-align: center;
  cursor: pointer;
  &::placeholder {
    font-weight: 550;
    font-size: 1rem;
    color: #5e5c5c;
  }
  &:focus {
    outline: none;
  }
`;
const SelectOptions = styled.div`
  position: absolute;
  left: 10%;
  margin-top: 0.5rem;
  background-color: white;
  /* background-color: pink; */
  font-size: 0.85rem;
  letter-spacing: 0.1rem;
  z-index: 3;
  width: 80%;
  display: ${({ isVisible }) => !isVisible && "none"};
  /* border-radius: 0px 0px 10px 10px; */
  box-shadow: 0px 4px 30px -12px rgba(0, 0, 0, 0.25);
`;
const Option = styled.div`
  padding: 0.5rem 0;
  cursor: pointer;
  font-weight: bold;
  color: #8d8d8d;
  text-align: start;
  &:hover {
    color: #641fe3;
    transform: scale(1.2);
    margin-left: 7.5%;
  }
`;

const CustomSelect = ({
  items,
  setSelectedItem,
  isSupplierPage,
}) => {
  const dropdownModal = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(items[0].name);

  const displayOptions = () => {
    setIsVisible((prev) => !prev);
  };

  const handleSelect = (item) => {
    if (isSupplierPage) {
      setSelectedItem(item)
      setSelectedCompany(item)
    } else setSelectedItem(() => {
      const filteredItem = items.filter((it) => it === item);
      return filteredItem;
    });
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropdownModal.current && !dropdownModal.current.contains(e.target)) {
        setIsVisible(false);
      }
    };
    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const keyEscCurrency = (e) => {
        if (e.code === "Escape") {
          setIsVisible(false);
        }
      };
      window.addEventListener("keydown", keyEscCurrency);
      return () => {
        window.removeEventListener("keydown", keyEscCurrency);
      };
    }
  }, [isVisible]);


  if (isSupplierPage) {
    return (
      <SelectWrapper ref={dropdownModal} onClick={displayOptions}>
        <div>
          <SelectInput readOnly placeholder={selectedCompany || ""} value={selectedCompany?.name} />
          <RiArrowDropDownLine className="arrow-down" fontWeight={900} />
        </div>
        <SelectOptions isVisible={isVisible} className="text-center">
          {items.map((item, index) => {
            return (
              <Option
                key={index}
                className="px-3"
                onClick={() => handleSelect(item)}
              >
                <span>{item.name}</span>
              </Option>
            );
          })}
        </SelectOptions>
      </SelectWrapper>
    );
  } else {
    return (
      <SelectWrapper ref={dropdownModal} onClick={displayOptions}>
        <div>
          <SelectInput
            readOnly
            placeholder={items[0]?.name ? items[0]?.name : ""}
          />
          <RiArrowDropDownLine className="arrow-down" fontWeight={900} />
        </div>
        <SelectOptions isVisible={isVisible} className="text-center">
          {items.map((item, index) => {
            return (
              <Option
                key={index}
                className="px-3"
                onClick={() => handleSelect(item)}
              >
                <span>{item.name}</span>
              </Option>
            );
          })}
        </SelectOptions>
      </SelectWrapper>
    );
  }
};

export default CustomSelect;
