import styled from "styled-components";
import { FaCalendar } from "react-icons/fa";

const CustomInput = styled.input`
  border: 2px solid #ebebeb;
  border-radius: 25px;
  width: 100%;
  height: 2rem;
  padding: 0.3rem 0.8rem;
  text-align: center;
  cursor: pointer;
  color: #5e5c5c;
  &:focus-visible {
    outline-style: none;
  }
`;

const IconStyle = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`;

const Input = ({ ...props }) => (
  <div className="d-flex flex-row justify-content-center">
    <CustomInput {...props} />
    <IconStyle>
      <FaCalendar color="#8D8D8D" />
    </IconStyle>
  </div>
);

export default Input;
