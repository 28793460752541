import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";
import styled from "styled-components";
import Input from "../Input/DateInput";
import moment, { isDate } from "moment";
import { useEffect, useRef } from "react";

const Calendar = styled.div`
  border-radius: 5px;
`;

const DatePick = ({
  selectedDate,
  setSelectedDate,
  amountRef,
  // isDateFocused,
  // setIsDateFocused,
}) => {
  const dateRef = useRef(null);

  let setDate = moment(selectedDate, "DD/MM/YYYY").toDate();

  const onKeyDown = (e) => {
    // console.log(e);
    if ((e.code = "Tab")) {
      dateRef.current.setOpen(false);
      // amountRef.current.focus();
      // console.log(amountRef);
    }
  };
  return (
    <DatePicker
      ref={dateRef}
      onKeyDown={onKeyDown}
      // onBlur={() => amountRef.current.focus()}
      selected={setDate}
      onChange={(date) => setSelectedDate(date)}
      calendarContainer={Calendar}
      customInput={<Input />}
      dateFormat="dd/MM/yyyy"
    />
  );
};

export default DatePick;
