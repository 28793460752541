import { Col, Modal, Row } from "react-bootstrap";
import ConfirmButton from "../Buttons/ConfirmButton";
import styled, { css } from "styled-components";
import RoundedInput from "../Input/RoundedInput";
import loading from "../../assets/loading.gif";
import CustomSelect from "../Select/CustomSelect";
import CheckSelect from "../Select/CheckSelect";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const Title = styled.span`
  ${fontFamilyUbuntu}
  font-weight: bold;
  font-size: 1.5rem;
  color: #000000;
`;

const OptionsLabel = styled.span`
  ${fontFamilyUbuntu}
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 16.09px;
  color: #000000;
  margin-left: 3rem;
`;

const LabelStartPosition = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

const SelectorContainer = styled.div`
  margin-top: 0.5rem;
  width: 100%;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NewSupplierModal = ({
  show,
  handleClose,
  handleSubmit,
  supplierName,
  setSupplierName,
  supplierTypes,
  selectedSupplierType,
  setSelectedSupplierType,
  message,
  submitting,
  companies,
  selectedCompanies,
  setSelectedCompanies,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <Row className="justify-content-end p-4">
          <Col xs={12} className="text-start">
            <Title>Create New Supplier</Title>
          </Col>
          <Col xs={12} className="text-center mt-4 mb-3">
            {/***** Inputs *****/}
            <>
              <Row className="d-flex flex-row justify-content-between mt-2 mb-5">
                {/***** Name *****/}
                <>
                  <Col className="d-flex flex-column align-items-start">
                    <LabelStartPosition>
                      <OptionsLabel>Supplier Name</OptionsLabel>
                    </LabelStartPosition>
                    <SelectorContainer>
                      <RoundedInput
                        value={supplierName}
                        onChange={setSupplierName}
                      />
                    </SelectorContainer>
                  </Col>
                </>
                {/***** Supplier Type *****/}
                <>
                  <Col className="d-flex flex-column align-items-start">
                    <OptionsLabel>Supplier Type</OptionsLabel>
                    <SelectorContainer>
                      <CustomSelect
                        isSupplierPage={true}
                        items={supplierTypes}
                        setSelectedItem={setSelectedSupplierType}
                        selectedItem={selectedSupplierType}
                      />
                    </SelectorContainer>
                  </Col>
                </>
                {/***** Companies *****/}
                <>
                  <Col
                    xs={12}
                    className="d-flex flex-column align-items-start mt-4 "
                  >
                    <OptionsLabel>Companies</OptionsLabel>
                    <SelectorContainer>
                      <CheckSelect
                        items={companies}
                        selectedItems={selectedCompanies}
                        setSelectedItems={setSelectedCompanies}
                      />
                    </SelectorContainer>
                  </Col>
                </>
              </Row>
            </>
          </Col>
          {/***** Buttons *****/}
          <>
            {/***** Close *****/}
            <>
              <Col xs="auto">
                <ConfirmButton
                  title="Close"
                  styling="py-1"
                  color="#DEDDDD"
                  borderColor="transparent"
                  onClick={handleClose}
                />
              </Col>
            </>
            {/***** Loading + Create*****/}
            <>
              <Col
                className="d-flex align-items-center justify-content-center"
                xs="auto"
              >
                {message ? (
                  <MessageContainer>{message}</MessageContainer>
                ) : submitting ? (
                  <img
                    src={loading}
                    className="img-fluid"
                    style={{ maxWidth: "20px" }}
                    alt="..."
                  />
                ) : (
                  <ConfirmButton
                    title="Create"
                    styling="py-1"
                    onClick={handleSubmit}
                  />
                )}
              </Col>
            </>
          </>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default NewSupplierModal;
