import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BsCheckSquareFill } from "react-icons/bs";
import { BsSquare } from "react-icons/bs";
import { Col, Row } from "react-bootstrap";

const SelectWrapper = styled.div`
  /* background-color: blue; */
  /* height: 200px; */
`;
const OptionWrapper = styled.div`
  cursor: pointer;
  /* width: 50%; */
  /* height: 100px; */
`;
const OptionText = styled.span``;

const checkObjIteration = (arrOfObj, toCheck) => {
  let isFound = arrOfObj.some((element) => {
    return element.id === toCheck ? true : false;
  });

  return isFound;
};

const OptionSelect = ({
  item,
  onClick,
  selectedItems,
  isInviteModal,
  isEdit,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleSelect = () => {
    onClick();
    setIsChecked((prev) => !prev);
  };

  useEffect(() => {
    if (!isInviteModal || isEdit) {
      return (
        selectedItems?.some((it) => it.id === item.id) && setIsChecked(true)
      );
    }
    // else {
    //   return (
    //     selectedItems.some((it) => it.id === item.id) && setIsChecked(true)
    //   );
    // }
  }, [selectedItems, item, isInviteModal, isEdit]);

  return (
    <OptionWrapper className="mb-2" onClick={handleSelect}>
      <Row className="g-0">
        <Col xs={2}>
          {isChecked ? <BsCheckSquareFill color="#641fe3" /> : <BsSquare />}
        </Col>
        <Col xs="auto" className="text-start">
          <OptionText>{item.name}</OptionText>
        </Col>
      </Row>
    </OptionWrapper>
  );
};

const CheckSelect = ({
  items,
  selectedItems,
  setSelectedItems,
  isInviteModal,
  isEdit,
}) => {
  const handleSelect = (id, item) => {
    setSelectedItems((prev) => {
      const addedItem = [...prev, { id: id, name: item }];
      const filtered = prev.filter((obj) => obj.id !== id);
      return checkObjIteration(prev, id) ? filtered : addedItem;
    });
  };

  return (
    <SelectWrapper className="">
      {items.map((item, index) => {
        return (
          <OptionSelect
            isInviteModal={isInviteModal}
            isEdit={isEdit}
            item={item}
            onClick={() => handleSelect(item.id, item.name)}
            selectedItems={selectedItems}
            key={index}
          />
        );
      })}
    </SelectWrapper>
    // )
  );
};

export default CheckSelect;
