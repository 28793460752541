export const textSplit = (str) => {
  return str.split("\n").map((it, i) => {
    return (
      <span key={i} className="row g-0">
        {it}
      </span>
    );
  });
};

export const extractNameFromEmail = (str, mailEnd) => {
  return str.split("@")[0].split(".").join(" ");
};
