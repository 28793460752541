import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LayoutWrapper from "../components/Layout/LayoutWrapper";
//import PageTitle from "../components/Titles/PageTitle";
import SearchBar from "../components/Search/SearchBar";
import Table from "../components/Table";
import ApiService from "../api/services";
import { getAccessToken } from "../utils/Auth";
import { ThreeDots } from "react-loader-spinner";
import TokenExpiredModal from "../components/Modal/TokenExpired";
import SwitchButton from "../components/Switch/SwitchButton";
import { getUserId } from "../utils/Auth";

const Home = () => {
  const userAccessId = getUserId("approval_user_id");
  /*** Invoices ***/
  const [invoices, setInvoices] = useState([]);
  const [allInvoices, setAllInvoices] = useState(invoices);
  /*** Search ***/
  const [search, setSearch] = useState("");
  /*** Booleans ***/
  const [isFilter, setIsFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaginationVisible, setIsPaginationVisible] = useState(false);
  const [showTokenExpiredModal, setShowTokenExpiredModal] = useState(false);
  // ** Pagination States ** //
  const [actualPageNumber, setActualPageNumber] = useState(1);
  const [lastPageNumber, setLastPageNumber] = useState("");

  const token = getAccessToken();

  const retrieveInvoices = useCallback(
    async (status, page, search) => {
      setIsLoading(true);
      try {
        let res = await ApiService.getAllInvoices(token, status, page, search);
        if (res && !res.isAxiosError) {
          const { data } = { ...res };
          if (data.meta.last_page === 1) {
            setIsPaginationVisible(false);
          } else {
            setIsPaginationVisible(true);
          }
          setActualPageNumber(data?.meta?.current_page);
          setLastPageNumber(data?.meta?.last_page && data?.meta?.last_page);
          setInvoices(data?.data);
          // }
        } else if (res.isAxiosError) {
          let response = res.response.statusText;
          if (response === "Unauthorized") {
            //token expired, redirect to login
            setShowTokenExpiredModal(true);
          }
        }
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    },
    [token]
  );

  const requestIncrementPage = () => {
    const filter = isFilter
      ? "partially_approved,submitted,fully_approved,declined"
      : "submitted,partially_approved";
    const searchBar = search ? search : "";
    if (actualPageNumber + 1 > lastPageNumber) {
      retrieveInvoices(filter, 1, searchBar);
    } else {
      retrieveInvoices(filter, actualPageNumber + 1, searchBar);
    }
  };

  const requestDecrementPage = () => {
    const filter = isFilter
      ? "partially_approved,submitted,fully_approved,declined"
      : "submitted,partially_approved";
    const searchBar = search ? search : "";
    if (actualPageNumber - 1 <= 0) {
      retrieveInvoices(filter, lastPageNumber, searchBar);
    } else {
      retrieveInvoices(filter, actualPageNumber - 1, searchBar);
    }
  };

  const handleSwitch = useCallback((e) => {
    setActualPageNumber(1);
    setIsFilter(e.target.checked);
  }, []);

  const handleSearch = (e) => {
    // setActualPageNumber(1);
    let input = e.target.value;
    setSearch(input);
    if (isFilter) {
      if (input.length >= 3) {
        retrieveInvoices(
          "partially_approved,submitted,fully_approved,declined",
          1,
          input
        );
      } else if (input.length === 0) {
        retrieveInvoices(
          "partially_approved,submitted,fully_approved,declined",
          1,
          ""
        );
      } else {
        return;
      }
    } else {
      if (input.length >= 3) {
        retrieveInvoices("partially_approved,submitted", 1, input);
      } else if (input.length === 0) {
        retrieveInvoices("partially_approved,submitted", 1, "");
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    if (token && !showTokenExpiredModal) {
      if (!isFilter) {
        retrieveInvoices("partially_approved,submitted", 1, "");
      } else {
        retrieveInvoices(
          "partially_approved,submitted,fully_approved,declined",
          1,
          ""
        );
      }
    }
  }, [token, showTokenExpiredModal, retrieveInvoices, isFilter]);

  useEffect(() => {
    setSearch("");
  }, [isFilter]);

  return (
    <LayoutWrapper page="home">
      <Container>
        <Row className="g-0 mt-5 pt-2 justify-content-between align-items-center">
          <Col xs={3}></Col>
          <Col xs={4} className="text-center pe-5">
            <SearchBar
              search={search}
              setSearch={setSearch}
              handleSearch={handleSearch}
            />
          </Col>
          <Col xs={3} className="text-end">
            <SwitchButton
              title="Show all invoices"
              checked={isFilter}
              handleChange={handleSwitch}
            />
          </Col>
        </Row>
      </Container>
      {isLoading ? (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <ThreeDots color="#641fe3" height="100" width="100" />
        </div>
      ) : (
        <Table
          userAccessId={userAccessId}
          isUploaded={false}
          isInvoicesTable={true}
          isPaginationVisible={isPaginationVisible}
          isFilter={isFilter}
          invoices={invoices}
          setInvoices={setInvoices}
          search={search}
          allInvoices={allInvoices}
          setAllInvoices={setAllInvoices}
          actualPageNumber={actualPageNumber}
          setActualPageNumber={setActualPageNumber}
          lastPageNumber={lastPageNumber}
          setLastPageNumber={setLastPageNumber}
          token={token}
          paginationRequests={{ requestIncrementPage, requestDecrementPage }}
          retrieveInvoices={() => {
            if (!isFilter) {
              retrieveInvoices("submitted,partially_approved", 1, "");
            } else {
              retrieveInvoices(
                "submitted,partially_approved,declined,fully_approved",
                1,
                ""
              );
            }
          }}
        />
      )}
      <TokenExpiredModal show={showTokenExpiredModal} />
    </LayoutWrapper>
  );
};

export default Home;
