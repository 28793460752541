import React, { useState, useEffect, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import LayoutWrapper from "../components/Layout/LayoutWrapper";
import SearchBar from "../components/Search/SearchBar";
//import PageTitle from "../components/Titles/PageTitle";
import Table from "../components/Table";
import UploadBtn from "../components/Buttons/UploadBtn";
import ApiService from "../api/services";
import { getAccessToken } from "../utils/Auth";
import { ThreeDots } from "react-loader-spinner";
import { getTodayDateFormatted } from "../utils/Date";
import TokenExpiredModal from "../components/Modal/TokenExpired";
import { getUserId } from "../utils/Auth";
import { BiRefresh } from "react-icons/bi";
import styled, { keyframes } from "styled-components";


const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const RotateIcon = styled(BiRefresh)`
  animation: ${rotate} 1s infinite linear ;
  font-size: 30px;
  cursor: pointer;
`;

const Uploaded = () => {
  const userAccessId = getUserId("approval_user_id");
  /*** Companies ***/
  const [companies, setCompanies] = useState([]);
  /*** Invoices ***/
  const [invoices, setInvoices] = useState([]);
  const [allInvoices, setAllInvoices] = useState(invoices);
  /*** Search ***/
  const [search, setSearch] = useState("");
  /*** Suppliers ***/
  const [suppliers, setSuppliers] = useState([]);
  const [searchSupplierSettings, setSearchSupplierSettings] = useState("");
  /*** Booleans ***/
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaginationVisible, setIsPaginationVisible] = useState(false);
  const [showTokenExpiredModal, setShowTokenExpiredModal] = useState(false);
  // ** Pagination States ** //
  const [actualPageNumber, setActualPageNumber] = useState(1);
  const [lastPageNumber, setLastPageNumber] = useState("");
  const [spinner, setSpinner] = React.useState(false)

  const token = getAccessToken();

  const loading = () => {
    setIsLoading((prev) => !prev);
  };

  const retrieveInvoices = useCallback(
    async (status, page, search) => {
      setIsLoading(true);
      try {
        let res = await ApiService.getAllInvoices(token, status, page, search);
        if (res && !res.isAxiosError) {
          const { data } = { ...res };
          if (data.meta.last_page === 1) {
            setIsPaginationVisible(false);
          } else {
            setIsPaginationVisible(true);
          }
          setActualPageNumber(data.meta.current_page);
          setLastPageNumber(data.meta.last_page);
          setInvoices(data?.data);
        } else if (res.isAxiosError) {
          let response = res.response.statusText;
          if (response === "Unauthorized") {
            //token expired, redirect to login
            setShowTokenExpiredModal(true);
          }
        }
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    },
    [token]
  );

  const requestIncrementPage = () => {
    const searchBar = search ? search : "";
    if (actualPageNumber + 1 > lastPageNumber) {
      retrieveInvoices("pending", 1, searchBar);
    } else {
      retrieveInvoices("pending", actualPageNumber + 1, searchBar);
    }
  };

  const requestDecrementPage = () => {
    const searchBar = search ? search : "";
    if (actualPageNumber - 1 <= 0) {
      retrieveInvoices("pending", lastPageNumber, searchBar);
    } else {
      retrieveInvoices("pending", actualPageNumber - 1, searchBar);
    }
  };

  const handleSearch = (e) => {
    // setActualPageNumber(1);
    let input = e.target.value;
    setSearch(input);
    if (input.length >= 3) {
      retrieveInvoices("pending", 1, input);
    } else if (input.length === 0) {
      retrieveInvoices("pending", 1, "");
    } else {
      return;
    }
  };

  const addInvoice = (pdfReport) => {
    let newInvoice = {
      id: null,
      invoiceNumber: "Invoice number",
      pdf_report: pdfReport,
      company: "NBX",
      supplierName: "Supplier",
      amount: 0,
      date: getTodayDateFormatted(),
      status: "pending",
      expanded: true,
    };
    setInvoices([newInvoice, ...invoices]);
  };

  const handleFileChange = (e) => {
    const selected = e.target.files[0];
    if (selected) {
      let reader = new FileReader();
      //let fileName = selected.name.replace(".pdf", "");
      reader.readAsDataURL(selected);
      reader.onloadend = (e) => {
        let base64Pdf = e.target.result;
        addInvoice(base64Pdf);
      };
    } else {
      console.log("ERROR");
    }
  };

  const handleRefreshInvoices = async () => {
    try {
      setTimeout(() => {
        setSpinner(false)
      }, 1000)
      setSpinner(true)
      let res = await ApiService.refreshInvoices(token);
      if (res && !res.isAxiosError) {
        // const { data } = { ...res };
        // console.log(data);
        setSearch("");
        retrieveInvoices("pending", 1, "");
      } else if (res.isAxiosError) {
        let response = res.response.statusText;
        if (response === "Unauthorized") {
          //token expired, redirect to login
          // setShowTokenExpiredModal(true);
          console.log(response);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const retrieveSuppliers = async (search) => {
      try {
        let res = await ApiService.getAllSuppliersSearch(token, search);
        if (res && !res.isAxiosError) {
          const { data } = { ...res };
          if (data?.data) {
            setSuppliers(data.data);
          }
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (token && !showTokenExpiredModal) {
      retrieveInvoices("pending", 1, "");
      retrieveSuppliers("");
    }
  }, [token, reload, showTokenExpiredModal, retrieveInvoices]);

  useEffect(() => {
    const receiveCompanies = async () => {
      try {
        let res = await ApiService.getCompanies(token);
        if (res && !res.isAxiosError) {
          const { data } = { ...res };
          if (data?.data) {
            setCompanies(data.data)
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
    receiveCompanies()
  }, [token])

  return (
    <LayoutWrapper page="uploaded">
      <Row className="g-0 mt-5 pt-2 justify-content-between">
        {/* <Col xs={2}></Col> */}
        <Col xs={3} className="text-end pe-5">
          {spinner ? <RotateIcon onClick={handleRefreshInvoices} /> : <BiRefresh fontSize={30} onClick={handleRefreshInvoices} cursor="pointer" />}
        </Col>
        <Col xs={4} className="text-center pe-5">
          <SearchBar wd="20vw" search={search} handleSearch={handleSearch} />
        </Col>

        <Col xs={3}>
          <UploadBtn
            title="Upload invoice"
            handleFileChange={handleFileChange}
          />
        </Col>
      </Row>
      {isLoading ? (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <ThreeDots color="#641fe3" height="100" width="100" />
        </div>
      ) : (
        <Table
          userAccessId={userAccessId}
          isUploaded={true}
          isInvoicesTable={true}
          isPaginationVisible={isPaginationVisible}
          invoices={invoices}
          search={search}
          companies={companies}
          setInvoices={setInvoices}
          suppliers={suppliers}
          setSuppliers={setSuppliers}
          allInvoices={allInvoices}
          setAllInvoices={setAllInvoices}
          token={token}
          loading={loading}
          reload={reload}
          setReload={setReload}
          actualPageNumber={actualPageNumber}
          setActualPageNumber={setActualPageNumber}
          lastPageNumber={lastPageNumber}
          setLastPageNumber={setLastPageNumber}
          searchSupplierSettings={searchSupplierSettings}
          setSearchSupplierSettings={setSearchSupplierSettings}
          paginationRequests={{ requestIncrementPage, requestDecrementPage }}
          retrieveInvoices={() => retrieveInvoices("pending", 1, "")}
        />
      )}
      <TokenExpiredModal show={showTokenExpiredModal} />
    </LayoutWrapper>
  );
};

export default React.memo(Uploaded);
