import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";

import invoice from "./report.pdf";

import Pagination from "../Pagination/Pagination";

const PdfViewer = ({ base64Pdf }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <div style={{ border: "2px solid grey" }}>
        <Document
          file={base64Pdf ? base64Pdf : invoice}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      {numPages > 1 && (
        <Pagination
          activePage={pageNumber}
          pages={numPages}
          setActivePage={setPageNumber}
          isPDF={true}
        />
      )}
    </>
  );
};

export default PdfViewer;
