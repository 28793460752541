import React, { useState, useEffect, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled, { css } from "styled-components";
import InvoiceRow from "./InvoiceRow";
import SupplierRow from "./SupplierRow";
import Pagination from "../Pagination/Pagination";
import ApiService from "../../api/services";
import CheckDeleteModal from "../Modal/CheckDeleteModal";
import SupplierDeletePopup from "../Modal/SignUpModal";
import ConfirmationPopup from "../Modal/ConfirmationPopup";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const ColTitle = styled.span`
  ${fontFamilyUbuntu}
  font-weight: 500;
  font-size: 1rem;
  line-height: 21px;
  color: #a8a8a8;
`;

const TableContainer = styled(Container)`
  margin-top: 4rem;
`;

const column_titles_uploaded = [
  { name: "Invoice Number", size: 2 },
  { name: "Company", size: 2 },
  { name: "Supplier", size: 2 },
  { name: "Amount", size: 2 },
  { name: "Date", size: 2 },
];

const column_titles_home = [
  { name: "Invoice Number", size: 2 },
  { name: "Company", size: 2 },
  { name: "Supplier", size: 2 },
  { name: "Amount", size: 2 },
  { name: "Date", size: 2 },
];

const suppliers_column_titles = [
  { name: "No.", size: 2 },
  { name: "Supplier", size: 4 },
  { name: "Supplier Type", size: 4 },
];

const ColTitleItem = ({ size, name }) => (
  <Col xs={size} className="text-center">
    <ColTitle>{name}</ColTitle>
  </Col>
);

const Table = ({
  isUploaded,
  isInvoicesTable,
  isFilter,
  isPaginationVisible,
  invoices,
  setInvoices,
  allInvoices,
  setAllInvoices,
  suppliers,
  setSuppliers,
  search,
  from,
  token,
  reload,
  setReload,
  userAccessId,
  addSupplier,
  lastPageNumber,
  actualPageNumber,
  setActualPageNumber,
  paginationRequests,
  updateSupplier,
  loading,
  retrieveSuppliers,
  isInfoUpdatePopupOpen,
  retrieveInvoices,
  companies,
  selectedCompanies,
  setSelectedCompanies,
  selectedCompaniesIDs,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoDeletePopupOpen, setIsInfoDeletePopupOpen] = useState(false);
  const [isSupplierUsed, setIsSupplierUsed] = useState(false);
  const [supplierUsedMessage, setSupplierUsedMessage] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [allInvoicesUpload, setAllInvoicesUpload] = useState(invoices);

  const removeSupplier = useCallback(
    async (id) => {
      try {
        let res = await ApiService.deleteSupplier(id, token);
        if (!res.isAxiosError) {
          // let result = suppliers?.filter((supplier) => supplier.id !== id);
          // setSuppliers(result);
          // setActualPageNumber(1);
          setIsInfoDeletePopupOpen(true);
          setTimeout(() => {
            retrieveSuppliers(1, "");
          }, 2000);
        } else {
          setSupplierUsedMessage(res?.response?.data?.error);
          setIsSupplierUsed(true);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [token, retrieveSuppliers]
  );

  const removeInvoice = useCallback(
    async (id) => {
      try {
        loading();
        await ApiService.deleteInvoice(id, token);
        let result = invoices.filter((invoice) => invoice.id !== id);
        setInvoices(result);
        loading();
      } catch (err) {
        console.error(err);
      }
    },
    [invoices, setInvoices, token, loading]
  );

  useEffect(() => {
    if (isInvoicesTable) {
      setAllInvoices(() => {
        const submittedInvoices = invoices?.filter(
          (invoice) => invoice.status === "submitted"
        );
        const fullyApprovedInvoices = invoices?.filter(
          (invoice) => invoice.status === "fully_approved"
        );
        const partiallyApprovedInvoices = invoices?.filter(
          (invoice) => invoice.status === "partially_approved"
        );
        const declinedInvoices = invoices?.filter(
          (invoice) => invoice.status === "declined"
        );

        if (!isUploaded && !isFilter) {
          return [...submittedInvoices, ...partiallyApprovedInvoices];
        } else if (!isUploaded && isFilter) {
          return [
            ...submittedInvoices,
            ...fullyApprovedInvoices,
            ...partiallyApprovedInvoices,
            ...declinedInvoices,
          ];
        }
      });
      if (isUploaded) {
        // console.log(invoices);
        setAllInvoicesUpload(
          invoices?.filter((invoice) => invoice.status === "pending")
        );
      }
    }
  }, [invoices, isInvoicesTable, setAllInvoices, isFilter, isUploaded]);

  const InvoicesTable = () => (
    <TableContainer>
      <Row className="mb-2 justity-content-between">
        <Col xs={1} className=""></Col>
        {isUploaded && (
          <Col xs={10}>
            <Row className="justity-content-between">
              {column_titles_uploaded.map((item, index) => (
                <ColTitleItem key={index} {...item} />
              ))}
            </Row>
          </Col>
        )}
        {!isUploaded && (
          <Col xs={11}>
            <Row className="justity-content-between">
              {column_titles_home.map((item, index) => (
                <ColTitleItem key={index} {...item} />
              ))}
            </Row>
          </Col>
        )}
        <Col xs={1}></Col>
      </Row>
      {/****** Rows display ******/}
      <>
        {isUploaded
          ? invoices?.map((invoice, index) => {
              return (
                <InvoiceRow
                  key={index}
                  {...invoice}
                  isUploaded={isUploaded}
                  invoices={allInvoicesUpload}
                  openModal={(id) => {
                    setInvoiceId(id);
                    setIsModalOpen(true);
                  }}
                  companies={companies}
                  setInvoices={setAllInvoicesUpload}
                  suppliers={suppliers}
                  setSuppliers={setSuppliers}
                  token={token}
                  reload={reload}
                  setReload={setReload}
                  retrieveInvoices={retrieveInvoices}
                />
              );
            })
          : invoices.map((invoice, index) => {
              return (
                <InvoiceRow
                  key={index}
                  {...invoice}
                  isUploaded={isUploaded}
                  userAccessId={userAccessId}
                  invoices={allInvoices}
                  setInvoices={setAllInvoices}
                  token={token}
                  retrieveInvoices={retrieveInvoices}
                />
              );
            })}
      </>

      {!isUploaded && isPaginationVisible && (
        <Row className="justify-content-center">
          <Col xs="auto">
            <Pagination
              pages={lastPageNumber}
              activePage={actualPageNumber}
              setActivePage={paginationRequests}
            />
          </Col>
        </Row>
      )}

      {isUploaded && isPaginationVisible && (
        <Row className="justify-content-center">
          <Col xs="auto">
            <Pagination
              pages={lastPageNumber}
              activePage={actualPageNumber}
              setActivePage={paginationRequests}
            />
          </Col>
        </Row>
      )}

      <CheckDeleteModal
        toBeDeleted="invoice"
        show={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        handleSubmit={() => {
          removeInvoice(invoiceId);
          setIsModalOpen(false);
        }}
      />
    </TableContainer>
  );

  const SuppliersTable = () => (
    <TableContainer>
      <Row className="mb-2">
        <Col xs={1}></Col>
        {suppliers_column_titles.map((item, index) => (
          <ColTitleItem key={index} {...item} />
        ))}
        <Col xs={1}></Col>
      </Row>
      {suppliers?.map((supplier, index) => {
        return (
          <SupplierRow
            key={index}
            index={from + index - 1}
            {...supplier}
            openModal={(id) => {
              setSupplierId(id);
              setIsModalOpen(true);
            }}
            updateSupplier={updateSupplier}
            companiesTotal={companies}
          />
        );
      })}
      {isPaginationVisible && (
        <Row className="justify-content-center">
          <Col xs="auto">
            <Pagination
              pages={lastPageNumber}
              activePage={actualPageNumber}
              setActivePage={paginationRequests}
            />
          </Col>
        </Row>
      )}

      {/*** Modals ***/}
      <SupplierDeletePopup
        isSuppliersPopup={true}
        showModal={isSupplierUsed}
        handleClose={() => setIsSupplierUsed(false)}
        message={supplierUsedMessage}
      />
      <CheckDeleteModal
        toBeDeleted="supplier"
        show={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        handleSubmit={() => {
          removeSupplier(supplierId);
          setIsModalOpen(false);
        }}
      />
      <ConfirmationPopup
        showModal={isInfoDeletePopupOpen}
        message="Supplier successfully deleted"
      />
      <ConfirmationPopup
        showModal={isInfoUpdatePopupOpen}
        message="Supplier successfully updated"
      />
    </TableContainer>
  );

  return <>{isInvoicesTable ? <InvoicesTable /> : <SuppliersTable />}</>;
};

export default React.memo(Table);
