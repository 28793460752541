import React from "react";
import styled, { css } from "styled-components";
import { AiFillWindows } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";

const fontFamilyRoboto = css`
  font-family: "Roboto";
`;

const Button = styled.button`
  width: 12.5rem;
  height: 2.125rem;
  border: 0.125rem solid #ebebeb;
  background-color: white;
  border-radius: 1.688rem;

  span {
    ${fontFamilyRoboto};
    font-weight: bold;
    font-size: 0.813rem;
    color: #641fe3;
    margin: 0;
    padding: 0;
  }

  &:hover {
    border-color: #641fe3;
  }
`;

const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

const SignInButton = ({ handleSignIn, setIsLoading }) => {
  return (
    <>
      <Button /*onClick={handleSignIn}*/>
        <Row className="g-0">
          <Col xs="2">
            <AiFillWindows fontSize="18px" color="#00ADEF" />
          </Col>
          <Col className="">
            <a
              href={`${LOGIN_URL}/api/login`}
              style={{ textDecoration: "none" }}
              onClick={() => setIsLoading(true)}
            >
              <span>Sign in with Microsoft</span>
            </a>
          </Col>
        </Row>
      </Button>
      {/*<Button onClick={handleSignIn}>
        <Row className="g-0">
          <Col xs="2">
            <AiFillWindows fontSize="18px" color="#00ADEF" />
          </Col>
          <Col className="">
            <span>Fake Sign in Microsoft</span>
          </Col>
        </Row>
      </Button>*/}
    </>
  );
};

export default SignInButton;
