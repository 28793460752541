import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import InviteButton from "../../components/Buttons/ConfirmButton";
import InviteUserModal from "../../components/Modal/InviteUserModal";
import UsersTable from "../../components/Table/UsersTable";
import CheckDeleteModal from "../../components/Modal/CheckDeleteModal";
import { getAccessToken } from "../../utils/Auth";
import ApiService from "../../api/services";
import { ThreeDots } from "react-loader-spinner";

const Users = () => {
  const token = getAccessToken();

  // *** Invites (users) *** //
  const [invites, setInvites] = useState([]);
  const [selectedInviteID, setSelectedInviteID] = useState("");

  // *** Companies *** //
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedCompaniesIDs, setSelectedCompaniesIDs] = useState([]);

  // *** Roles *** //
  const [roles, setRoles] = useState([]);
  const [selectedRoleID, setSelectedRoleID] = useState("");

  // *** Booleans *** //
  const [isInviteModal, setIsInviteModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [showTokenExpiredModal, setShowTokenExpiredModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // *** Values *** //
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  // *** Value changing / Modal functions *** //

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };
  const handleCloseModal = useCallback(() => {
    isInviteModal && setIsInviteModal(false);
    isEditModal && setIsEditModal(false);
    setEmail("");
    setRole("");
  }, [isEditModal, isInviteModal]);

  const handleOpenEditModal = (id) => {
    const filteredInvite = invites.filter((inv) => inv.id === id);
    // For placeholders
    setEmail(filteredInvite[0].email);
    setRole([filteredInvite[0].role]);
    // For API call
    setSelectedRoleID(filteredInvite[0].role.id);
    setSelectedInviteID(filteredInvite[0].id);
    setSelectedCompanies(filteredInvite[0].companies);
    // Open modal after
    setIsEditModal(true);
  };

  const handleOpenDeleteModal = (id) => {
    const filteredInvite = invites.filter((inv) => inv.id === id);
    setSelectedInviteID(filteredInvite[0].id);
    setIsDeleteModal(true);
  };

  // *** API functions *** //
  const retrieveInvites = useCallback(async () => {
    setIsLoading(true);
    try {
      let res = await ApiService.getInvites(token);

      if (res && !res.isAxiosError) {
        const { data } = { ...res };
        // console.log(typeof data?.data);
        setInvites(data?.data);
      } else if (res.isAxiosError) {
        let response = res.response.statusText;
        if (response === "Unauthorized") {
          //token expired, redirect to login
          setShowTokenExpiredModal(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }, [token]);

  const retrieveCompanies = useCallback(async () => {
    try {
      let res = await ApiService.getCompanies(token);

      if (res && !res.isAxiosError) {
        const { data } = { ...res };
        setCompanies(data?.data);
      } else if (res.isAxiosError) {
        let response = res.response.statusText;
        if (response === "Unauthorized") {
          //token expired, redirect to login
          setShowTokenExpiredModal(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  const retrieveRoles = useCallback(async () => {
    try {
      let res = await ApiService.getRoles(token);

      if (res && !res.isAxiosError) {
        const { data } = { ...res };
        setRoles(data?.data);
      } else if (res.isAxiosError) {
        let response = res.response.statusText;
        if (response === "Unauthorized") {
          //token expired, redirect to login
          setShowTokenExpiredModal(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  const createInvite = useCallback(
    async (email, role, companies) => {
      try {
        let res = await ApiService.createInvite(token, email, role, companies);

        if (res && !res.isAxiosError) {
          handleCloseModal();
          retrieveInvites();
        } else if (res.isAxiosError) {
          let response = res.response.statusText;
          if (response === "Unauthorized") {
            //token expired, redirect to login
            setShowTokenExpiredModal(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [token, handleCloseModal, retrieveInvites]
  );

  const editInvite = useCallback(
    async (id, role_id, companies_ids) => {
      try {
        let res = await ApiService.editInvite(
          token,
          id,
          role_id,
          companies_ids
        );

        if (res && !res.isAxiosError) {
          handleCloseModal();
          retrieveInvites();
        } else if (res.isAxiosError) {
          let response = res.response.statusText;
          if (response === "Unauthorized") {
            //token expired, redirect to login
            setShowTokenExpiredModal(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [token, handleCloseModal, retrieveInvites]
  );

  const deleteInvite = useCallback(
    async (id) => {
      try {
        let res = await ApiService.deleteInvite(token, id);

        if (res && !res.isAxiosError) {
          setIsDeleteModal(false);
          retrieveInvites();
        } else if (res.isAxiosError) {
          let response = res.response.statusText;
          if (response === "Unauthorized") {
            //token expired, redirect to login
            setShowTokenExpiredModal(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [token, retrieveInvites]
  );

  // *** Submit functions *** //

  const handleInviteSubmit = () => {
    createInvite(email, selectedRoleID, selectedCompaniesIDs);
  };
  const handleEditSubmit = () => {
    editInvite(selectedInviteID, selectedRoleID, selectedCompaniesIDs);
  };
  const handleDeleteSubmit = () => {
    deleteInvite(selectedInviteID);
  };

  useEffect(() => {
    if (token && !showTokenExpiredModal) {
      retrieveInvites();
      retrieveCompanies();
      retrieveRoles();
    }
  }, [
    token,
    showTokenExpiredModal,
    retrieveInvites,
    retrieveCompanies,
    retrieveRoles,
  ]);

  useEffect(() => {
    setSelectedCompaniesIDs(() => {
      const newValues = selectedCompanies.reduce((total, curr) => {
        total.push(curr.id);
        return total;
      }, []);
      return newValues;
    });
  }, [selectedCompanies]);

  useEffect(() => {
    setSelectedRoleID(() => role[0]?.id || "");
  }, [role]);

  return (
    <>
      <Row className="mt-4">
        {/***** Visible Content *****/}
        <>
          <Col xs={1} className="me-4"></Col>
          {isLoading ? (
            <>
              <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                <ThreeDots color="#641fe3" height="100" width="100" />
              </div>
            </>
          ) : (
            <>
              <Col xs={11} className="text-end">
                <InviteButton
                  onClick={() => setIsInviteModal(true)}
                  title="Invite users"
                />
              </Col>
              <Row className="g-0">
                <Col xs={1} className="me-4"></Col>
                <Col className="mt-4 ps-3">
                  <UsersTable
                    invites={invites}
                    openEditModal={handleOpenEditModal}
                    opedDeleteModal={handleOpenDeleteModal}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
        {/*****  Modals *****/}
        <>
          {/*** Invite user ***/}
          <>
            <InviteUserModal
              showModal={isInviteModal}
              handleClose={handleCloseModal}
              handleSubmit={handleInviteSubmit}
              title="New User"
              email={email}
              companies={companies}
              selectedCompanies={selectedCompanies}
              setSelectedCompanies={setSelectedCompanies}
              role={role}
              roles={roles}
              setRole={setRole}
              handleEmailChange={handleEmailChange}
              handleRoleChange={handleRoleChange}
            />
          </>
          {/*** Edit invited user ***/}
          <>
            <InviteUserModal
              showModal={isEditModal}
              handleClose={handleCloseModal}
              handleSubmit={handleEditSubmit}
              title="Edit User"
              isEdit={true}
              email={email}
              companies={companies}
              selectedCompanies={selectedCompanies}
              setSelectedCompanies={setSelectedCompanies}
              role={role}
              roles={roles}
              setRole={setRole}
              handleEmailChange={handleEmailChange}
              handleRoleChange={handleRoleChange}
            />
          </>
          {/*** Delete user ***/}
          <>
            <CheckDeleteModal
              show={isDeleteModal}
              handleClose={() => setIsDeleteModal(false)}
              handleSubmit={handleDeleteSubmit}
              toBeDeleted="user"
            />
          </>
        </>
      </Row>
    </>
  );
};

export default Users;
