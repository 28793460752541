import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import styled, { css } from "styled-components";
import ConfirmButton from "../Buttons/ConfirmButton";
import RoundedInput from "../Input/RoundedInput";
import CheckSelect from "../Select/CheckSelect";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const Text = styled.div`
  ${fontFamilyUbuntu};
  font-weight: bold;
  font-size: 1.25rem;
  color: #000000;
  text-align: ${({ isSuppliersPopup }) =>
    isSuppliersPopup ? "center" : "left"};
  margin: auto;
  margin-left: 2rem;
  @media (max-width: 75rem) {
    font-size: 1.25rem;
    text-align: center;
  }
  @media (max-width: 28.125rem) {
    font-size: 1.125rem;
    text-align: center;
  }
`;

const OptionsLabel = styled.span`
  ${fontFamilyUbuntu}
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 16.09px;
  color: #000000;
  margin-left: 1rem;
  margin-bottom: 0.25rem;
`;

const SelectorContainer = styled.div`
  margin-top: 0.5rem;
  width: 100%;
`;

const LabelStartPosition = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

const RoleModal = ({
  showModal,
  handleClose,
  handleSubmit,
  title,
  isAddRole,
  role,
  description,
  handleRoleChange,
  handleDescriptionChange,
  permissions,
  selectedPermissions,
  setSelectedPermisions,
}) => {
  const [isSecondStep, setIsSecondStep] = useState(false);

  const nextStep = () => {
    if (isAddRole && !isSecondStep) setIsSecondStep(true);
    if (isAddRole && isSecondStep) {
      handleSubmit();
      // handleClose();
      // setIsSecondStep(false);
    }
    if (!isAddRole) handleSubmit();
  };

  return (
    <>
      <Modal
        show={showModal}
        size="md"
        onHide={() => handleClose(setIsSecondStep)}
      >
        <ModalHeader>
          <Row className="g-0 w-100">
            <Col xs={6} className="d-flex flex-column align-items-start mt-2">
              <Text className="my-xl-1 my-1 p-0">{title}</Text>
            </Col>
          </Row>
        </ModalHeader>
        <Modal.Body className={`p-3 px-0 text-xl-center text-center`}>
          {/*** Second step ADD ***/}
          {isAddRole && isSecondStep && (
            <Row className="g-0 justify-content-center">
              <Col xs={8} className="d-flex flex-column align-items-start mt-4">
                <LabelStartPosition>
                  <OptionsLabel>Permissions *</OptionsLabel>
                </LabelStartPosition>
                <SelectorContainer>
                  <CheckSelect
                    items={permissions}
                    selectedItems={selectedPermissions}
                    setSelectedItems={setSelectedPermisions}
                  />
                </SelectorContainer>
              </Col>
            </Row>
          )}

          {/*** First step ADD ***/}
          {isAddRole ? (
            !isSecondStep && (
              <Row className="g-0 justify-content-center">
                <Col
                  xs={8}
                  className="d-flex flex-column align-items-start mt-2"
                >
                  <LabelStartPosition>
                    <OptionsLabel>Role name*</OptionsLabel>
                  </LabelStartPosition>
                  <SelectorContainer>
                    <RoundedInput value={role} onChange={handleRoleChange} />
                  </SelectorContainer>
                </Col>
                <Col
                  xs={8}
                  className="d-flex flex-column align-items-start mt-4"
                >
                  <LabelStartPosition>
                    <OptionsLabel>Description*</OptionsLabel>
                  </LabelStartPosition>
                  <SelectorContainer>
                    <RoundedInput
                      value={description}
                      onChange={handleDescriptionChange}
                    />
                  </SelectorContainer>
                </Col>
              </Row>
            )
          ) : (
            // *** Edit Role *** //
            <Row className="g-0 justify-content-center">
              <Col xs={8} className="d-flex flex-column align-items-start mt-2">
                <LabelStartPosition>
                  <OptionsLabel>Role name*</OptionsLabel>
                </LabelStartPosition>
                <SelectorContainer>
                  <RoundedInput value={role} onChange={handleRoleChange} />
                </SelectorContainer>
              </Col>

              <Col xs={8} className="d-flex flex-column align-items-start mt-4">
                <LabelStartPosition>
                  <OptionsLabel>Description*</OptionsLabel>
                </LabelStartPosition>
                <SelectorContainer>
                  <RoundedInput
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                </SelectorContainer>
              </Col>

              <Col xs={8} className="d-flex flex-column align-items-start mt-4">
                <LabelStartPosition>
                  <OptionsLabel>Permissions *</OptionsLabel>
                </LabelStartPosition>
                <SelectorContainer>
                  <CheckSelect
                    items={permissions}
                    selectedItems={selectedPermissions}
                    setSelectedItems={setSelectedPermisions}
                  />
                </SelectorContainer>
              </Col>
            </Row>
          )}
          <Col xs={11} className="text-end">
            <ConfirmButton
              styling={`mb-xl-2 mt-5 py-1`}
              title={
                isAddRole
                  ? isSecondStep
                    ? "Create Role"
                    : "Next step"
                  : "Save"
              }
              onClick={nextStep}
            />
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RoleModal;
