import React from "react";
import styled from "styled-components";
import { AiOutlineSearch } from "react-icons/ai";

const Search = styled.div`
  position: relative;
  width: ${({ wd }) => wd};
  min-width: 20rem;
  margin: auto;
  input {
    border: 0.125rem solid #ebebeb;
    border-radius: 1.688rem;
    background-color: white;
    padding-left: 1rem;
    width: 100%;
    font-size: 1.125rem;
    color: #5e5c5c;
  }
  input:focus {
    outline: none;
  }
  svg {
    position: absolute;
    margin-left: -2rem;
    top: 25%;
  }
`;

const SearchBar = ({ wd, search, handleSearch }) => {
  return (
    <Search wd={wd}>
      <input type="text" value={search} onChange={handleSearch} />
      <AiOutlineSearch color="#641FE3" />
    </Search>
  );
};

export default SearchBar;
