import React from "react";
import { Modal } from "react-bootstrap";
import styled, { css } from "styled-components";
import ConfirmButton from "../Buttons/ConfirmButton";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const Text = styled.div`
  ${fontFamilyUbuntu};
  font-weight: bold;
  font-size: 1.625rem;
  color: #000000;
  text-align: ${({ isSuppliersPopup }) =>
    isSuppliersPopup ? "center" : "left"};
  margin: auto;
  @media (max-width: 75rem) {
    font-size: 1.25rem;
    text-align: center;
  }
  @media (max-width: 28.125rem) {
    font-size: 1.125rem;
    text-align: center;
  }
`;

const SignUpModal = ({ showModal, handleClose, message, isSuppliersPopup }) => {
  return (
    <>
      <Modal show={showModal} size="md" onHide={handleClose}>
        <Modal.Body
          className={`p-4 ${
            isSuppliersPopup && "pt-0"
          } text-xl-center text-center`}
        >
          <Text className="my-xl-5 my-4" isSuppliersPopup={isSuppliersPopup}>
            {message}
          </Text>
          <ConfirmButton
            // styling="mb-xl-4 mt-xl-1 mt-4 me-xl-4 py-1"
            styling={`mb-xl-2 mt-xl-1 mt-4 me-xl-${
              isSuppliersPopup ? "0" : "4"
            } py-1`}
            title="OK"
            onClick={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SignUpModal;
