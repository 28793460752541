import { useState } from "react";
import styled from "styled-components";

const InputStyle = styled.input`
  width: 100%;
  height: 2rem;
  border: 2px solid #ebebeb;
  border-radius: 25px;
  outline: none;
  text-align: center;
`;

const Suggestions = styled.div`
  z-index: 9999;
  position: absolute;
  left: 10%;
  background-color: white;
  width: 80%;
  border: none;
  cursor: pointer;
  margin-top: 0.5rem;
  font-size: 0.85rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  box-shadow: 0px 4px 30px -12px rgba(0, 0, 0, 0.25);
  div:hover {
    color: #641fe3;
  }
`;

const AutoComplete = ({ suggestions, supplierName, setSupplierName }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const onChange = (e) => {
    const userInput = e.target.value;

    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setSupplierName(e.target.value);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const onClick = (e) => {
    setFilteredSuggestions([]);
    setSupplierName(e.target.innerText);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
  };

  const onKeyDown = (e) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setSupplierName(filteredSuggestions[activeSuggestionIndex]);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestionIndex === 0) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  const SuggestionsListComponent = () => {
    return (
      filteredSuggestions.length > 0 && (
        <Suggestions>
          {filteredSuggestions.map((suggestion, index) => {
            return (
              <div key={suggestion} onClick={onClick} className="py-2">
                {suggestion}
              </div>
            );
          })}
        </Suggestions>
      )
    );
  };

  return (
    <>
      <InputStyle
        type="text"
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={supplierName}
      />
      {showSuggestions && supplierName && <SuggestionsListComponent />}
    </>
  );
};

export default AutoComplete;
