import styled from "styled-components";
import { AiFillLeftCircle } from "react-icons/ai";
import { AiFillRightCircle } from "react-icons/ai";

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin: 0 1rem;
    width: ${({ isPDF }) => isPDF && "5rem"};
  }
`;

const Pagination = ({ pages, activePage, setActivePage, isPDF }) => {
  const decrement = () => {
    return activePage === 1
      ? setActivePage(pages)
      : setActivePage((prev) => prev - 1);
  };
  const increment = () => {
    return activePage === pages
      ? setActivePage(1)
      : setActivePage((prev) => prev + 1);
  };

  return (
    <PaginationWrapper className="mt-4 mb-4" isPDF={isPDF}>
      <AiFillLeftCircle
        cursor="pointer"
        color="#641fe3"
        fontSize="1.25rem"
        onClick={!isPDF ? setActivePage.requestDecrementPage : decrement}
      />
      <span>
        {activePage}/{pages}
      </span>
      <AiFillRightCircle
        cursor="pointer"
        color="#641fe3"
        fontSize="1.25rem"
        onClick={!isPDF ? setActivePage.requestIncrementPage : increment}
      />
    </PaginationWrapper>
  );
};

export default Pagination;
