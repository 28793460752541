import React from "react";
import styled, { css } from "styled-components";

const fontFamilyUbuntu = css`
  font-family: "Ubuntu";
`;

const Hyperlink = styled.span`
  ${fontFamilyUbuntu};
  background: white;
  padding: 0.3rem 0rem;
  font-weight: bold;
  font-size: 1rem;
  color: ${({ color }) => color || "#641fe3"};
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const SupplierHyperlink = ({ title, onClick }) => {
  return <Hyperlink onClick={onClick}>{title}</Hyperlink>;
};

export default SupplierHyperlink;
