import React, { useContext, createContext, useState } from "react";
import { Navigate } from "react-router-dom";

const windowGlobal = typeof window !== "undefined" && window;

const fakeAuth = {
  isAuthenticated: false,
  signin(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    removeAccessToken();
    setTimeout(cb, 100);
  },
};

const authContext = createContext();

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useAuth() {
  //return useContext(authContext);
  return getAccessToken();
}

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signin = (cb) => {
    return fakeAuth.signin(() => {
      setUser("user");
      cb();
    });
  };

  const signout = (cb) => {
    return fakeAuth.signout(() => {
      setUser(null);
      cb();
    });
  };

  return {
    user,
    signin,
    signout,
  };
}

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  //return auth?.user ? children : <Navigate to="/" />;
  return auth ? children : <Navigate to="/" />;
}

const setAccessToken = (token) => {
  if (windowGlobal) {
    localStorage.setItem("approval_access_token", token);
  }
};
const setIsAdmin = (isAdmin) => {
  if (windowGlobal) {
    localStorage.setItem("isAdmin", isAdmin);
  }
};
const getIsAdmin = () => {
  if (windowGlobal) {
    localStorage.getItem("isAdmin");
  }
};

function getAccessToken() {
  if (windowGlobal) {
    return localStorage.getItem("approval_access_token");
  }
}

function removeAccessToken() {
  if (windowGlobal) {
    localStorage.setItem("approval_access_token", "");
  }
}

const setUserId = (userId) => {
  if (windowGlobal) {
    localStorage.setItem("approval_user_id", userId);
  }
};

function getUserId() {
  if (windowGlobal) {
    return localStorage.getItem("approval_user_id");
  }
}

const setUserName = (userName) => {
  if (windowGlobal) {
    localStorage.setItem("approval_user_name", userName);
  }
};

function getUserName() {
  if (windowGlobal) {
    return localStorage.getItem("approval_user_name");
  }
}

function setPermision(role) {
  if (windowGlobal) {
    return localStorage.getItem("approval_admin_role", role);
  }
}

function getPermision() {
  if (windowGlobal) {
    return localStorage.getItem("approval_admin_role");
  }
}

function logOut(cb) {
  fakeAuth.signout(cb);
}

export {
  ProvideAuth,
  PrivateRoute,
  useAuth,
  logOut,
  setAccessToken,
  setIsAdmin,
  getIsAdmin,
  getAccessToken,
  setUserId,
  getUserId,
  getUserName,
  setUserName,
  setPermision,
  getPermision,
};
