import { useEffect } from 'react'
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Title = styled.span`
  font-family: Ubuntu;
  font-weight: regular;
  font-size: 1rem;
  color: #000000;
`;

const TokenExpiredModal = ({show}) => {
  let history = useNavigate();

  useEffect(() => {
  	if(show)
  	{
  		setTimeout(() => {
	      history("/")
	    }, 3000);
  	}
  }, [history, show]);

  return (
    <Modal show={show} centered>
      <Modal.Body>
        <Title>Token expired, redirecting to login in 3 seconds...</Title>
      </Modal.Body>
    </Modal>
  );
};

export default TokenExpiredModal;
